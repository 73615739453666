import { css } from '@emotion/react'
import { ReactElement } from 'react'
import ReactFancyQrCode from 'react-fancy-qrcode'

import COLOR, { GRADIENT } from 'src/constants/color'
import QrLogo from 'src/images/qr-code-logo.png'

// Sets the border radius on the corner markers, [outsideRing, insideSquare]
const POSITION_RADIUS = ['5%', '2%']

// Sets the radius of the inner dots
const DOT_RADIUS = '50%'

// Sets the scale of the inner dots
const DOT_SCALE = 0.75

/**
 * Sets the 'density' of the QR code. Higher error correction may
 * improve scanability at the cost of design aesthetics.
 */
const ERROR_CORRECTION = 'H'
const QR_CODE_SIZE = 140
const QR_LOGO_SIZE = 35

/**
 * Displays a custom QR code with a logo in the center. QR codes should
 * not be hard-coded as SVG images, as this can lead to accessibility
 * issues and difficulty updating URLs, and does not allow flexibility
 * per environment.
 *
 * @param value The value to encode in the QR code, such as a URL.
 */
export default function QrCode({ value }: { value: string }): ReactElement {
  return (
    <div
      css={css`
        position: relative;
        border-radius: 18px;
        background: ${GRADIENT.PURPLE_LIGHT};
        padding: 8px;
      `}
    >
      <div
        css={css`
          position: relative;
          border-radius: 14px;
          background: ${COLOR.WHITE};
          padding: 8px;
        `}
      >
        <ReactFancyQrCode
          value={value}
          size={QR_CODE_SIZE}
          dotScale={DOT_SCALE}
          dotRadius={DOT_RADIUS}
          positionRadius={POSITION_RADIUS}
          errorCorrection={ERROR_CORRECTION}
          logo={QrLogo}
          logoSize={QR_LOGO_SIZE}
        />
      </div>
    </div>
  )
}
