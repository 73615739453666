import { css } from '@emotion/react'
import { useCallback, useState } from 'react'

import Button from 'src/components/Button'
import { fontFamilies, TextH3, TextParagraph } from 'src/components/Text'
import COLOR from 'src/constants/color'
import { BankAccountAddressMask } from 'src/features/trade/components/ViewAccount'
import { useTrade } from 'src/features/trade/hooks/useTrade'
import {
  CRYPTO_ASSETS,
  ExternalAccount,
} from 'src/features/trade/hooks/useTradeFunding'
import { WithdrawalType } from 'src/features/trade/hooks/useTradeWithdraw'
import {
  formStyles,
  inputGroupStyles,
  labelStyles,
  inputStyles,
  subgroupButton,
  selectedSubgroupButton,
} from 'src/features/trade/styles/DemoStyles'

// Demo account IDs for testnet withdrawals
const DEMO_ACCOUNT_IDS = {
  TBTC: 'zerohash-testnet-tbtc',
  TETH: 'zerohash-testnet-teth',
} as const

/**
 * Constants for the Trade Withdraw page, held in an object to allow for easy
 * access, but should be replaced with i18n strings in a non-demo environment.
 */
export const TRADE_WITHDRAW_TEXT = {
  TITLE: 'Step 4: Withdraw Funds',
  TIPS: [
    'Enter the withdrawal amount and destination details. For wire transfers, please ensure all bank information is accurate.',
  ],
  DESCRIPTION: 'Enter the amount you want to withdraw',
  AMOUNT_LABEL: 'Amount',
  WALLET_ACCOUNT_LABEL: 'Wallet Account ID',
  AMOUNT_PLACEHOLDER: 'Enter amount',
  WALLET_ACCOUNT_PLACEHOLDER: 'Enter wallet account ID',
  GET_QUOTE: 'Get Withdrawal Quote',
  EXECUTE: 'Execute Withdrawal',
  QUOTE_DETAILS: 'Withdrawal Quote',
  AMOUNT: 'Amount',
  NETWORK_FEE: 'Network Fee',
  ACH_FEE: 'ACH Fee',
  WIRE_FEE: 'Wire Fee',
  EXPIRES: 'Expires',
  WITHDRAWAL_EXECUTED: 'Withdrawal Executed',
  STATUS: 'Status',
  TRANSACTION_ID: 'Transaction ID',
  ERROR: {
    QUOTE: 'Failed to get withdrawal quote',
    EXECUTE: 'Failed to execute withdrawal',
  },
  DEV_NOTES: {
    QUOTE: 'Withdrawal quotes are provided by ZeroHash and include fees',
    EXECUTE:
      'Withdrawals are processed through ZeroHash and may take time to complete',
  },
  SELECT_ASSET: 'Select Asset',
  WITHDRAWAL_TYPE: {
    CRYPTO: 'Crypto',
    ACH: 'ACH Transfer',
    WIRE: 'Wire Transfer',
  },
  WIRE_FORM: {
    BENEFICIARY_NAME_PLACEHOLDER: 'Bank account holder name',
    BANK_NAME_PLACEHOLDER: 'Bank name',
    ROUTING_NUMBER_PLACEHOLDER: 'Routing number (US Domestic)',
    SWIFT_CODE_PLACEHOLDER: 'SWIFT Code (International)',
    ACCOUNT_NUMBER_PLACEHOLDER: 'Bank account number',
    RECIPIENT_INSTRUCTIONS_PLACEHOLDER: 'Memo (optional)',
  },
  ACH_FORM: {
    SELECT_BANK_ACCOUNT: 'Select bank account',
  },
  DEMO_ACCOUNTS: {
    TITLE: 'Demo Accounts',
    DESCRIPTION: 'Use these test accounts for demo withdrawals:',
    TBTC: 'Bitcoin Testnet Account',
    TETH: 'Ethereum Testnet Account',
  },
}

/**
 * Trade Withdraw Component Architecture
 * ===================================
 *
 * Component Hierarchy
 * ------------------
 * TradeWithdraw
 *   └─ WithdrawalForm
 *      └─ QuoteDetails
 *         └─ ExecutionResult
 *
 * Data Flow
 * ---------
 * 1. User Input -> Amount & Wallet Account ID
 * 2. Quote Request -> Fee Calculation
 * 3. Quote Confirmation -> Execution
 * 4. Transaction Result Display
 */
export function TradeWithdraw() {
  const [withdrawalType, setWithdrawalType] = useState<WithdrawalType>('CRYPTO')

  const { withdrawState } = useTrade()
  const { isLoading, error, quote, result, executeWithdrawal } = withdrawState

  const handleWithdrawalTypeChange = useCallback((type: WithdrawalType) => {
    setWithdrawalType(type)
  }, [])

  const handleExecute = useCallback(async () => {
    if (quote?.details.withdrawalId != null) {
      await executeWithdrawal(quote.details.withdrawalId)
    }
  }, [quote, executeWithdrawal])

  return (
    <div css={containerStyles}>
      <div css={withdrawalTypeStyles}>
        <button
          type='button'
          css={[
            subgroupButton,
            withdrawalType === 'CRYPTO' && selectedSubgroupButton,
          ]}
          onClick={() => handleWithdrawalTypeChange('CRYPTO')}
        >
          {TRADE_WITHDRAW_TEXT.WITHDRAWAL_TYPE.CRYPTO}
        </button>
        <button
          type='button'
          css={[
            subgroupButton,
            withdrawalType === 'ACH' && selectedSubgroupButton,
          ]}
          onClick={() => handleWithdrawalTypeChange('ACH')}
        >
          {TRADE_WITHDRAW_TEXT.WITHDRAWAL_TYPE.ACH}
        </button>
        <button
          type='button'
          css={[
            subgroupButton,
            withdrawalType === 'WIRE' && selectedSubgroupButton,
          ]}
          onClick={() => handleWithdrawalTypeChange('WIRE')}
        >
          {TRADE_WITHDRAW_TEXT.WITHDRAWAL_TYPE.WIRE}
        </button>
      </div>

      {withdrawalType === 'CRYPTO' && <WithdrawWithCrypto />}
      {withdrawalType === 'ACH' && <WithdrawWithAch />}
      {withdrawalType === 'WIRE' && <WithdrawWithWire />}

      {error != null && (
        <TextParagraph css={errorStyles}>{error}</TextParagraph>
      )}

      {quote != null && (
        <div css={quoteStyles}>
          <TextH3 css={subtitleStyles}>
            {TRADE_WITHDRAW_TEXT.QUOTE_DETAILS}
          </TextH3>
          <div css={quoteDetailsStyles}>
            <TextParagraph>
              {TRADE_WITHDRAW_TEXT.AMOUNT} {quote.amount.raw}
            </TextParagraph>
            {quote.type === 'CRYPTO' ? (
              <TextParagraph>
                {TRADE_WITHDRAW_TEXT.NETWORK_FEE} {quote.networkFee?.raw}
              </TextParagraph>
            ) : quote.type === 'ACH' ? (
              <TextParagraph>
                {TRADE_WITHDRAW_TEXT.ACH_FEE} {quote.achFee?.raw}
              </TextParagraph>
            ) : (
              <TextParagraph>
                {TRADE_WITHDRAW_TEXT.WIRE_FEE} {quote.wireFee}
              </TextParagraph>
            )}
          </div>
          <Button
            onClick={handleExecute}
            disabled={isLoading}
            variant='alternate-inverted'
            size='small'
            css={buttonStyles}
          >
            {TRADE_WITHDRAW_TEXT.EXECUTE}
          </Button>
          <TextParagraph css={devNoteStyles}>
            {TRADE_WITHDRAW_TEXT.DEV_NOTES.QUOTE}
          </TextParagraph>
        </div>
      )}

      {result && (
        <div css={resultStyles}>
          <TextH3 css={subtitleStyles}>
            {TRADE_WITHDRAW_TEXT.WITHDRAWAL_EXECUTED}
          </TextH3>
          <TextParagraph>
            {TRADE_WITHDRAW_TEXT.STATUS} {result.status}
          </TextParagraph>
          {result.networkTxId != null && (
            <TextParagraph>
              {TRADE_WITHDRAW_TEXT.TRANSACTION_ID} {result.networkTxId}
            </TextParagraph>
          )}
          <TextParagraph css={devNoteStyles}>
            {TRADE_WITHDRAW_TEXT.DEV_NOTES.EXECUTE}
          </TextParagraph>
        </div>
      )}
    </div>
  )
}

const containerStyles = css`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  padding: 24px;
  background: ${COLOR.WHITE_ALPHA_50};
  border-radius: 12px;
`

const buttonStyles = css`
  width: 100%;
  margin-top: 8px;
`

const errorStyles = css`
  color: ${COLOR.RED_500};
  font-size: 14px;
  margin-top: 16px;
  font-family: ${fontFamilies};
`

const quoteStyles = css`
  margin-top: 24px;
  padding: 16px;
  background: ${COLOR.BLACK};
  border-radius: 8px;
`

const resultStyles = css`
  margin-top: 24px;
  padding: 16px;
  background: ${COLOR.BLACK};
  border-radius: 8px;
`

const subtitleStyles = css`
  color: ${COLOR.WHITE};
  font-size: 18px;
  margin-bottom: 16px;
  font-family: ${fontFamilies};
`

const quoteDetailsStyles = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  margin-bottom: 16px;
`

const devNoteStyles = css`
  color: ${COLOR.PURPLE_100};
  font-size: 14px;
  margin-top: 12px;
  font-style: italic;
  font-family: ${fontFamilies};
`

const withdrawalTypeStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 24px;
`

const assetList = css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  width: 100%;
`

const textareaStyles = css`
  min-height: 80px;
  resize: vertical;
`

const demoAccountsStyles = css`
  padding: 16px;
  background: ${COLOR.BLACK};
  border-radius: 8px;
`

const demoAccountsTitleStyles = css`
  color: ${COLOR.WHITE};
  font-size: 16px;
  margin-bottom: 8px;
  font-family: ${fontFamilies};
`

const demoAccountsDescriptionStyles = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  margin-bottom: 16px;
  font-family: ${fontFamilies};
`

const demoAccountButtonsStyles = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const demoAccountButton = css`
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid ${COLOR.PURPLE_100};
  background: transparent;
  color: ${COLOR.PURPLE_100};
  font-size: 14px;
  font-family: ${fontFamilies};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${COLOR.PURPLE_100};
    color: ${COLOR.WHITE};
  }
`

function WithdrawWithCrypto() {
  const [amount, setAmount] = useState('')
  const [walletAccountId, setWalletAccountId] = useState('')
  const [selectedAsset, setSelectedAsset] = useState(CRYPTO_ASSETS[0])

  const { withdrawState } = useTrade()
  const { isLoading, getWithdrawalQuote } = withdrawState

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()
      await getWithdrawalQuote({
        type: 'CRYPTO',
        amount,
        asset: selectedAsset,
        walletAccountId,
      })
    },
    [amount, selectedAsset, walletAccountId, getWithdrawalQuote],
  )

  return (
    <form onSubmit={handleSubmit} css={formStyles}>
      <div css={assetList}>
        {CRYPTO_ASSETS.map((asset) => (
          <button
            key={asset}
            type='button'
            css={[
              subgroupButton,
              asset === selectedAsset && selectedSubgroupButton,
            ]}
            onClick={() => setSelectedAsset(asset)}
          >
            {asset}
          </button>
        ))}
      </div>

      <div css={demoAccountsStyles}>
        <TextH3 css={demoAccountsTitleStyles}>
          {TRADE_WITHDRAW_TEXT.DEMO_ACCOUNTS.TITLE}
        </TextH3>
        <TextParagraph css={demoAccountsDescriptionStyles}>
          {TRADE_WITHDRAW_TEXT.DEMO_ACCOUNTS.DESCRIPTION}
        </TextParagraph>
        <div css={demoAccountButtonsStyles}>
          <button
            type='button'
            css={demoAccountButton}
            onClick={() => setWalletAccountId(DEMO_ACCOUNT_IDS.TBTC)}
          >
            {TRADE_WITHDRAW_TEXT.DEMO_ACCOUNTS.TBTC}
          </button>
          <button
            type='button'
            css={demoAccountButton}
            onClick={() => setWalletAccountId(DEMO_ACCOUNT_IDS.TETH)}
          >
            {TRADE_WITHDRAW_TEXT.DEMO_ACCOUNTS.TETH}
          </button>
        </div>
      </div>

      <div css={inputGroupStyles}>
        <label css={labelStyles}>{TRADE_WITHDRAW_TEXT.AMOUNT_LABEL}</label>
        <input
          type='text'
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder={TRADE_WITHDRAW_TEXT.AMOUNT_PLACEHOLDER}
          css={inputStyles}
        />
      </div>

      <div css={inputGroupStyles}>
        <label css={labelStyles}>
          {TRADE_WITHDRAW_TEXT.WALLET_ACCOUNT_LABEL}
        </label>
        <input
          type='text'
          value={walletAccountId}
          onChange={(e) => setWalletAccountId(e.target.value)}
          placeholder={TRADE_WITHDRAW_TEXT.WALLET_ACCOUNT_PLACEHOLDER}
          css={inputStyles}
        />
      </div>

      <Button
        type='submit'
        disabled={isLoading}
        variant='alternate-inverted'
        size='small'
        css={buttonStyles}
      >
        {TRADE_WITHDRAW_TEXT.GET_QUOTE}
      </Button>
    </form>
  )
}

function WithdrawWithAch() {
  const [amount, setAmount] = useState('')
  const [selectedBankAccount, setSelectedBankAccount] =
    useState<ExternalAccount | null>(null)
  const [error, setError] = useState<string | null>(null)
  const { fundingState, withdrawState } = useTrade()
  const { isLoading, getWithdrawalQuote } = withdrawState

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()
      if (selectedBankAccount == null) {
        setError('No bank account selected')
        return
      }
      setError(null)
      await getWithdrawalQuote({
        type: 'ACH',
        amount,
        asset: selectedBankAccount.asset,
        destination: {
          externalAccountId: selectedBankAccount.externalAccountId,
        },
      })
    },
    [amount, selectedBankAccount, getWithdrawalQuote],
  )

  return (
    <form onSubmit={handleSubmit} css={formStyles}>
      <div css={inputGroupStyles}>
        <label css={labelStyles}>
          {TRADE_WITHDRAW_TEXT.ACH_FORM.SELECT_BANK_ACCOUNT}
        </label>
        <div
          css={css`
            display: flex;
            gap: 12px;
          `}
        >
          {fundingState.bankAccounts.map((account) => (
            <button
              key={`${account.id}-${account.externalBankAccountMask}`}
              type='button'
              css={[
                subgroupButton,
                account.id === selectedBankAccount?.id &&
                  selectedSubgroupButton,
              ]}
              onClick={() => setSelectedBankAccount(account)}
            >
              <BankAccountAddressMask
                address={account.externalBankAccountMask}
              />
            </button>
          ))}
        </div>
      </div>

      <div css={inputGroupStyles}>
        <label css={labelStyles}>{TRADE_WITHDRAW_TEXT.AMOUNT_LABEL}</label>
        <input
          type='text'
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder={TRADE_WITHDRAW_TEXT.AMOUNT_PLACEHOLDER}
          css={inputStyles}
        />
      </div>

      <Button
        type='submit'
        disabled={isLoading}
        variant='alternate-inverted'
        size='small'
        css={buttonStyles}
      >
        {TRADE_WITHDRAW_TEXT.EXECUTE}
      </Button>

      {error != null && (
        <TextParagraph css={errorStyles}>{error}</TextParagraph>
      )}
    </form>
  )
}

function WithdrawWithWire() {
  const [amount, setAmount] = useState('')
  const [bankName, setBankName] = useState('')
  const [beneficiaryName, setBeneficiaryName] = useState('')
  const [accountNumber, setAccountNumber] = useState('')
  const [routingNumber, setRoutingNumber] = useState('')
  const [swiftCode, setSwiftCode] = useState('')
  const [recipientInstructions, setRecipientInstructions] = useState('')

  const { withdrawState } = useTrade()
  const { isLoading, getWithdrawalQuote } = withdrawState

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()
      await getWithdrawalQuote({
        type: 'WIRE',
        amount,
        asset: 'USD',
        destination: {
          name: bankName,
          beneficiaryName,
          accountNumber,
          bankName,
          swiftCode,
          routingNumber,
        },
        recipientInstructions,
      })
    },
    [
      amount,
      bankName,
      beneficiaryName,
      accountNumber,
      routingNumber,
      swiftCode,
      recipientInstructions,
      getWithdrawalQuote,
    ],
  )

  return (
    <form onSubmit={handleSubmit} css={formStyles}>
      <div css={inputGroupStyles}>
        <label css={labelStyles}>{TRADE_WITHDRAW_TEXT.AMOUNT}</label>
        <input
          type='text'
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder={TRADE_WITHDRAW_TEXT.AMOUNT_PLACEHOLDER}
          css={inputStyles}
        />
      </div>

      <div css={inputGroupStyles}>
        <label css={labelStyles}>
          {TRADE_WITHDRAW_TEXT.WIRE_FORM.BENEFICIARY_NAME_PLACEHOLDER}
        </label>
        <input
          type='text'
          value={beneficiaryName}
          onChange={(e) => setBeneficiaryName(e.target.value)}
          placeholder={
            TRADE_WITHDRAW_TEXT.WIRE_FORM.BENEFICIARY_NAME_PLACEHOLDER
          }
          css={inputStyles}
        />
      </div>

      <div css={inputGroupStyles}>
        <label css={labelStyles}>
          {TRADE_WITHDRAW_TEXT.WIRE_FORM.BANK_NAME_PLACEHOLDER}
        </label>
        <input
          type='text'
          value={bankName}
          onChange={(e) => setBankName(e.target.value)}
          placeholder={TRADE_WITHDRAW_TEXT.WIRE_FORM.BANK_NAME_PLACEHOLDER}
          css={inputStyles}
        />
      </div>

      <div css={inputGroupStyles}>
        <label css={labelStyles}>
          {TRADE_WITHDRAW_TEXT.WIRE_FORM.ROUTING_NUMBER_PLACEHOLDER}
        </label>
        <input
          type='text'
          value={routingNumber}
          onChange={(e) => setRoutingNumber(e.target.value)}
          placeholder={TRADE_WITHDRAW_TEXT.WIRE_FORM.ROUTING_NUMBER_PLACEHOLDER}
          css={inputStyles}
        />
      </div>

      <div css={inputGroupStyles}>
        <label css={labelStyles}>
          {TRADE_WITHDRAW_TEXT.WIRE_FORM.SWIFT_CODE_PLACEHOLDER}
        </label>
        <input
          type='text'
          value={swiftCode}
          onChange={(e) => setSwiftCode(e.target.value)}
          placeholder={TRADE_WITHDRAW_TEXT.WIRE_FORM.SWIFT_CODE_PLACEHOLDER}
          css={inputStyles}
        />
      </div>

      <div css={inputGroupStyles}>
        <label css={labelStyles}>
          {TRADE_WITHDRAW_TEXT.WIRE_FORM.ACCOUNT_NUMBER_PLACEHOLDER}
        </label>
        <input
          type='text'
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
          placeholder={TRADE_WITHDRAW_TEXT.WIRE_FORM.ACCOUNT_NUMBER_PLACEHOLDER}
          css={inputStyles}
        />
      </div>

      <div css={inputGroupStyles}>
        <label css={labelStyles}>
          {TRADE_WITHDRAW_TEXT.WIRE_FORM.RECIPIENT_INSTRUCTIONS_PLACEHOLDER}
        </label>
        <textarea
          value={recipientInstructions}
          onChange={(e) => setRecipientInstructions(e.target.value)}
          placeholder={
            TRADE_WITHDRAW_TEXT.WIRE_FORM.RECIPIENT_INSTRUCTIONS_PLACEHOLDER
          }
          css={[inputStyles, textareaStyles]}
        />
      </div>

      <Button
        type='submit'
        disabled={isLoading}
        variant='alternate-inverted'
        size='small'
        css={buttonStyles}
      >
        {TRADE_WITHDRAW_TEXT.EXECUTE}
      </Button>
    </form>
  )
}
