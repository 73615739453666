import { useState, useCallback, useEffect, useRef } from 'react';
import useApi from 'src/hooks/useApi'; /**
 * Withdrawal System Architecture
 * ============================
 *
 * System Overview
 * --------------
 * This module implements a withdrawal system that manages:
 * - Withdrawal quote requests (both crypto and wire)
 * - Fee calculations (network fees for crypto, wire fees for fiat)
 * - Quote expiration handling
 * - Withdrawal execution
 *
 * Data Flow Sequence
 * -----------------
 * 1. Quote Request
 *    - User inputs amount and destination details
 *    - System calculates appropriate fees
 *    - Returns time-limited quote
 *
 * 2. Quote Management
 *    - Tracks quote expiration
 *    - Validates quote before execution
 *    - Handles quote refresh if needed
 *
 * 3. Withdrawal Execution
 *    - Processes withdrawal with quote
 *    - Handles transaction processing
 *    - Returns execution status
 */ // Constants for withdrawal system
const WITHDRAWAL_CONSTANTS = { QUOTE_EXPIRY_MS: 300000, // 5 minutes
    QUOTE_REFRESH_BUFFER_MS: 10000 // 10 second buffer before expiry
};
function convertRequestToJsonMap(request) { const { type, ...rest } = request; return { type, ...rest }; }
export function useTradeWithdraw() {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [quote, setQuote] = useState(null);
    const [result, setResult] = useState(null);
    const [quoteExpired, setQuoteExpired] = useState(false); // Timer ref for quote expiration
    const quoteExpiryTimeoutRef = useRef(null);
    const { fetch: fetchWithdrawal } = useApi({ serviceName: 'trade', path: 'tradeWithdrawals', method: 'POST' });
    const { fetch: fetchWithdrawalExecute } = useApi({ serviceName: 'trade', path: 'tradeWithdrawals/{withdrawalId}/execute', method: 'POST' }); // Clear quote and timers
    const clearQuoteState = useCallback(() => { if (quoteExpiryTimeoutRef.current) {
        clearTimeout(quoteExpiryTimeoutRef.current);
        quoteExpiryTimeoutRef.current = null;
    } setQuote(null); setQuoteExpired(false); }, []); // Schedule quote expiration
    const scheduleQuoteExpiration = useCallback(() => { if (quoteExpiryTimeoutRef.current) {
        clearTimeout(quoteExpiryTimeoutRef.current);
    } quoteExpiryTimeoutRef.current = setTimeout(() => { setQuoteExpired(true); setQuote(null); }, WITHDRAWAL_CONSTANTS.QUOTE_EXPIRY_MS - WITHDRAWAL_CONSTANTS.QUOTE_REFRESH_BUFFER_MS); }, []);
    const getWithdrawalQuote = useCallback(async (request) => { setIsLoading(true); setError(null); try {
        const response = await fetchWithdrawal({ body: convertRequestToJsonMap(request) });
        if (response.error != null) {
            throw new Error('Failed to get withdrawal quote');
        }
        setQuote(response.data);
        scheduleQuoteExpiration();
    }
    catch (err) {
        setError(err instanceof Error ? err.message : 'Unknown error occurred');
    }
    finally {
        setIsLoading(false);
    } }, [fetchWithdrawal, scheduleQuoteExpiration]);
    const executeWithdrawal = useCallback(async (quoteId) => { if (quoteExpired) {
        setError('Quote has expired. Please request a new quote.');
        return;
    } setIsLoading(true); setError(null); try {
        const response = await fetchWithdrawalExecute({ pathData: { withdrawalId: quoteId }, body: { quoteId } });
        if (response.error != null) {
            throw new Error('Failed to execute withdrawal');
        }
        setResult(response.data);
        clearQuoteState();
    }
    catch (err) {
        setError(err instanceof Error ? err.message : 'Unknown error occurred');
    }
    finally {
        setIsLoading(false);
    } }, [fetchWithdrawalExecute, clearQuoteState, quoteExpired]); // Cleanup on unmount
    useEffect(() => { return () => { if (quoteExpiryTimeoutRef.current) {
        clearTimeout(quoteExpiryTimeoutRef.current);
    } }; }, []);
    return { isLoading, error, quote, result, quoteExpired, getWithdrawalQuote, executeWithdrawal, discardQuote: clearQuoteState };
}
