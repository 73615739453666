import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState, useCallback, useEffect, Fragment } from 'react'

import Button from 'src/components/Button'
import PillTabs, { PillTabOption } from 'src/components/PillTabs'
import PlainButton from 'src/components/PlainButton'
import QrCode from 'src/components/QrCode'
import SkeletonLoader from 'src/components/SkeletonLoader'
import Spacer from 'src/components/Spacer'
import {
  TextParagraph,
  TextCode,
  TextSpan,
  baseTextStyles,
} from 'src/components/Text'
import COLOR from 'src/constants/color'
import {
  BTC_COIN_TYPES,
  COIN_TYPE,
  ETH_COIN_TYPES,
} from 'src/constants/wallet-accounts'
import {
  ActivityList,
  PlaidLinkComponent,
} from 'src/features/trade/components/FundAccount'
import { useTrade } from 'src/features/trade/hooks/useTrade'
import {
  Account,
  AccountStatus,
  AccountType,
  CRYPTO_ASSETS,
  ExternalAccount,
  FIAT_ASSETS,
} from 'src/features/trade/hooks/useTradeFunding'
import { digitalDisplayStyle } from 'src/features/trade/styles/DemoStyles'
import useApi from 'src/hooks/useApi'
import { ReactComponent as BackIcon } from 'src/images/chevron-left.svg'

export const VIEW_ACCOUNT_TEXT = {
  TITLE: 'Your Accounts',
  TOTAL_VALUE: 'Total Portfolio Value',
  CASH_ACCOUNTS: 'Cash Accounts',
  CRYPTO_ACCOUNTS: 'Crypto Accounts',
  BANK_ACCOUNTS: 'Bank Accounts',
  BITCOIN_NETWORK: 'Bitcoin Network',
  ETHEREUM_NETWORK: 'Ethereum Network',
  ERROR: 'Unable to load accounts',
  NO_ACCOUNTS: 'No accounts found',
  NO_ADDRESS: 'No address generated',
  POWERED_BY: 'POWERED BY ZEROHASH',
  TOTAL_BALANCE: 'Total Balance',
  BACK: 'Back',
  BALANCE: 'Balance',
  DISCONNECT_BANK_ACCOUNT: 'Disconnect Bank Account',
  RECONNECT_BANK_ACCOUNT: 'Reconnect Bank Account',
  WALLET_ACCOUNTS_NOTES: [
    'This is a simulated list of wallet accounts.',
    'You DO NOT need to send from your actual wallet to fund your trading account.',
    'Follow the steps below to get funded:',
  ],
  WALLET_ACCOUNTS_STEPS: [
    'Select the wallet account of the asset you want to send to fund your trading account.',
    'Scan the QR code or copy the address to send funds from any wallet.',
    'When ZeroHash observes the transaction on the blockchain, it reports a "movement" and we create a pending deposit to your trading account.',
  ],
  DEPOSIT: (coinType: COIN_TYPE) => `Deposit ${coinType}`,
  REFRESH_ACCOUNTS: 'Refresh Accounts',
} as const

export const CURRENCY_CONSTANTS = {
  DEFAULT_FIAT: 'USD',
  DEFAULT_AMOUNT: '$0.00',
} as const

const tagText = (fontSize: number) => css`
  color: ${COLOR.WHITE_ALPHA_800};
  background: ${COLOR.WHITE_ALPHA_200};
  padding: ${fontSize / 10}px ${fontSize / 3}px;
  border-radius: ${fontSize / 3}px;
  font-size: ${fontSize}px;
  font-weight: 500;
`

const BankAccountImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
`

export function AccountRow({
  account,
  onClick,
}: {
  account: Account
  onClick?: () => void
}) {
  const Wrapper = onClick ? PlainButton : 'div'
  return (
    <Wrapper
      css={[accountRowContainer, onClick && rowButton]}
      onClick={onClick}
    >
      <div css={assetContainer}>
        <AccountIcon account={account} />
        <TextSpan css={assetText}>
          {(account as ExternalAccount).externalBankAccountName ??
            account.asset}
          {account.status !== AccountStatus.ACTIVE && (
            <TextSpan
              css={[
                tagText(12),
                css`
                  margin-left: 4px;
                `,
              ]}
            >
              {account.status}
            </TextSpan>
          )}
        </TextSpan>
      </div>
      <div css={balanceContainer}>
        {account.type === AccountType.EXTERNAL ? (
          <TextSpan css={balanceText}>
            <BankAccountAddressMask address={account.externalBankAccountMask} />
          </TextSpan>
        ) : (
          <TextSpan css={balanceText}>{account.balance.formatted}</TextSpan>
        )}
        {account.type !== AccountType.EXTERNAL &&
          account.asset !== CURRENCY_CONSTANTS.DEFAULT_FIAT && (
            <TextSpan css={fiatBalanceText}>
              {account.fiat[CURRENCY_CONSTANTS.DEFAULT_FIAT].formatted}
            </TextSpan>
          )}
      </div>
    </Wrapper>
  )
}

function WalletAccountRow({
  coinType,
  onClick,
}: {
  coinType: COIN_TYPE
  onClick: () => void
}) {
  return (
    <PlainButton
      key={coinType}
      css={[accountRowContainer, rowButton]}
      onClick={onClick}
    >
      <div css={assetContainer}>
        <AssetIcon asset={coinType} />
        <TextSpan css={assetText}>{coinType}</TextSpan>
      </div>
    </PlainButton>
  )
}

function CurrencyDetail({
  asset,
  balance,
  fiatValue,
  onBack,
  children,
}: {
  asset: string
  balance: string
  fiatValue: string
  onBack: () => void
  children: React.ReactNode
}) {
  return (
    <div css={container}>
      <BackButton onClick={onBack}>
        <BackIcon css={backIcon} />
        <TextSpan css={backText}>{VIEW_ACCOUNT_TEXT.BACK}</TextSpan>
      </BackButton>
      <TextParagraph css={currencyTitle}>
        {VIEW_ACCOUNT_TEXT.BALANCE} {asset}
      </TextParagraph>
      <div css={digitalBalanceContainer}>
        <TextParagraph css={digitalDisplayStyle}>{balance}</TextParagraph>
        {asset !== CURRENCY_CONSTANTS.DEFAULT_FIAT && (
          <TextParagraph css={subBalance}>{fiatValue}</TextParagraph>
        )}
      </div>
      {children}
    </div>
  )
}

function BankAccountDetail({
  account,
  onBack,
  onDisconnect,
  onReconnect,
}: {
  account: ExternalAccount
  onBack: () => void
  onDisconnect: () => void
  onReconnect: () => void
}) {
  const { fundingState } = useTrade()
  const [isDisconnecting, setIsDisconnecting] = useState(false)
  const [isReconnecting, setIsReconnecting] = useState(false)
  const [linkToken, setLinkToken] = useState<string | null>(null)

  const onClickReconnect = async () => {
    setIsReconnecting(true)
    const linkToken = await fundingState.createLinkToken({
      tradeAccountId: account.id,
    })
    setLinkToken(linkToken ?? null)
  }

  const onClickDisconnect = async () => {
    setIsDisconnecting(true)
    const { success } = await fundingState.disconnectTradeAccount(account.id)
    setIsDisconnecting(false)

    if (success) {
      onDisconnect()
    }
  }

  const onLinkFlowSuccess = async (
    publicToken: string,
    metadata: { account_id: string },
  ) => {
    console.log('Plaid publicToken generated %o', metadata)
    const tradeAccountId = await fundingState.updateTradeAccount(
      account.id,
      publicToken,
    )
    setIsReconnecting(false)
    if (tradeAccountId == null) {
      console.error('Failed to update Plaid tradeAccount')
      return
    }
    console.log('Plaid tradeAccount updated %o', tradeAccountId)
    onReconnect()
  }

  const onLinkFlowExit = () => {
    console.log('Exited Plaid flow')
    setLinkToken(null)
  }

  return (
    <div css={container}>
      {linkToken != null && (
        <PlaidLinkComponent
          linkToken={linkToken}
          onSuccess={onLinkFlowSuccess}
          onExit={onLinkFlowExit}
        />
      )}

      <div css={containerHeader}>
        <BackButton onClick={onBack}>
          <BackIcon css={backIcon} />
          <TextSpan css={backText}>{VIEW_ACCOUNT_TEXT.BACK}</TextSpan>
        </BackButton>
      </div>

      <div css={digitalBalanceContainer}>
        <TextSpan css={digitalDisplayStyle}>{account.asset}</TextSpan>
        <TextSpan css={currencyTitle}>
          <BankAccountAddressMask address={account.externalBankAccountMask} />
        </TextSpan>

        {account.status !== AccountStatus.ACTIVE && (
          <TextSpan css={tagText(14)}>{account.status}</TextSpan>
        )}

        <Spacer unit={20} />

        {account.status === AccountStatus.INACTIVE && (
          <>
            <Button
              size='small'
              variant='alternate-inverted'
              loading={isReconnecting}
              onClick={onClickReconnect}
            >
              {VIEW_ACCOUNT_TEXT.RECONNECT_BANK_ACCOUNT}
            </Button>
            <Spacer unit={4} />
          </>
        )}

        <Button
          size='small'
          variant='danger'
          loading={isDisconnecting}
          onClick={onClickDisconnect}
        >
          {VIEW_ACCOUNT_TEXT.DISCONNECT_BANK_ACCOUNT}
        </Button>
      </div>

      {fundingState.errors.createLinkToken != null && (
        <TextParagraph css={errorText}>
          {fundingState.errors.createLinkToken}
        </TextParagraph>
      )}

      {fundingState.errors.updateTradeAccount != null && (
        <TextParagraph css={errorText}>
          {fundingState.errors.updateTradeAccount}
        </TextParagraph>
      )}
    </div>
  )
}

type AccountTab = 'trade-accounts' | 'wallet-accounts'

const ACCOUNT_TAB_OPTIONS: readonly PillTabOption<AccountTab>[] = [
  { value: 'trade-accounts', label: 'Trade Accounts' },
  { value: 'wallet-accounts', label: 'Wallet Accounts' },
] as const

export default function ViewAccount() {
  const [activeTab, setActiveTab] = useState<PillTabOption<AccountTab>>(
    ACCOUNT_TAB_OPTIONS[0],
  )
  const { fundingState } = useTrade()
  return (
    <div css={container}>
      <PillTabs
        name='account-type'
        options={ACCOUNT_TAB_OPTIONS}
        activeOption={activeTab}
        setActiveOption={setActiveTab}
        invertColors={true}
      />
      <Spacer unit={3} />
      {activeTab.value === 'trade-accounts' ? (
        <ViewTradeAccountTab />
      ) : (
        <ViewWalletAccountTab />
      )}
      <Spacer unit={3} />
      <Button
        size='small'
        variant='alternate-inverted'
        onClick={() => fundingState.loadAccounts()}
      >
        {VIEW_ACCOUNT_TEXT.REFRESH_ACCOUNTS}
      </Button>
    </div>
  )
}

function ViewTradeAccountTab() {
  const { fundingState } = useTrade()
  const { accounts, bankAccounts, loading, error, summary, loadActivity } =
    fundingState
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)

  const handleInternalAccountClick = useCallback(
    async (account: Account) => {
      setSelectedAccount(account)
      if (account !== selectedAccount) {
        await loadActivity(account.asset)
      }
    },
    [selectedAccount, loadActivity],
  )

  const handleExternalAccountClick = useCallback((account: Account) => {
    setSelectedAccount(account)
  }, [])

  if (loading) {
    return <ViewAccountLoader />
  }

  if (error != null) {
    return (
      <TextParagraph css={errorText}>{VIEW_ACCOUNT_TEXT.ERROR}</TextParagraph>
    )
  }

  if (accounts.length === 0) {
    return <TextParagraph>{VIEW_ACCOUNT_TEXT.NO_ACCOUNTS}</TextParagraph>
  }

  const portfolioTotal =
    summary?.[CURRENCY_CONSTANTS.DEFAULT_FIAT]?.formatted ??
    CURRENCY_CONSTANTS.DEFAULT_AMOUNT

  const cashAccounts = accounts
    .filter((account) => FIAT_ASSETS.includes(account.asset))
    // Sort the default fiat asset to the top
    .sort((a, b) => (a.asset === CURRENCY_CONSTANTS.DEFAULT_FIAT ? -1 : 1))

  const cryptoAccounts = accounts
    .filter((account) => CRYPTO_ASSETS.includes(account.asset))
    // Sort the default fiat asset to the top
    .sort((a, b) => (a.asset === CURRENCY_CONSTANTS.DEFAULT_FIAT ? -1 : 1))

  if (selectedAccount != null) {
    if (selectedAccount.type === AccountType.EXTERNAL) {
      return (
        <BankAccountDetail
          account={selectedAccount}
          onBack={() => setSelectedAccount(null)}
          onDisconnect={() => setSelectedAccount(null)}
          onReconnect={() => setSelectedAccount(null)}
        />
      )
    }

    return (
      <CurrencyDetail
        asset={selectedAccount.asset}
        balance={selectedAccount.balance.formatted}
        fiatValue={
          selectedAccount.fiat[CURRENCY_CONSTANTS.DEFAULT_FIAT].formatted
        }
        onBack={() => setSelectedAccount(null)}
      >
        <ActivityList
          activityLoading={fundingState.activityLoading}
          activity={fundingState.activity}
          onRefresh={() => loadActivity(selectedAccount.asset)}
        />
      </CurrencyDetail>
    )
  }

  return (
    <>
      <div css={digitalBalanceContainer}>
        <TextSpan css={balanceLabel}>
          {VIEW_ACCOUNT_TEXT.TOTAL_BALANCE}
        </TextSpan>
        <TextSpan css={digitalDisplayStyle}>{portfolioTotal}</TextSpan>
        <TextSpan css={poweredByText}>{VIEW_ACCOUNT_TEXT.POWERED_BY}</TextSpan>
      </div>

      {cashAccounts.length > 0 && (
        <div css={accountsSection}>
          <TextParagraph css={sectionHeader}>
            {VIEW_ACCOUNT_TEXT.CASH_ACCOUNTS}
          </TextParagraph>
          <div css={cashAccountsContainer}>
            {cashAccounts.map((account) => (
              <AccountRow
                key={account.asset}
                account={account}
                onClick={() => handleInternalAccountClick(account)}
              />
            ))}
          </div>
        </div>
      )}

      {cryptoAccounts.length > 0 && (
        <div css={accountsSection}>
          <TextParagraph css={sectionHeader}>
            {VIEW_ACCOUNT_TEXT.CRYPTO_ACCOUNTS}
          </TextParagraph>
          <div css={accountsList}>
            {cryptoAccounts.map((account) => (
              <AccountRow
                key={account.asset}
                account={account}
                onClick={() => handleInternalAccountClick(account)}
              />
            ))}
          </div>
        </div>
      )}

      {bankAccounts.length > 0 && (
        <div css={accountsSection}>
          <TextParagraph css={sectionHeader}>
            {VIEW_ACCOUNT_TEXT.BANK_ACCOUNTS}
          </TextParagraph>
          <div css={accountsList}>
            {bankAccounts.map((account) => (
              <AccountRow
                key={`${account.asset}-${
                  account.externalBankAccountMask ?? ''
                }-${account.id}`}
                account={account}
                onClick={() => handleExternalAccountClick(account)}
              />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

function ViewWalletAccountTab() {
  const [selectedCoinType, setSelectedCoinType] =
    useState<COIN_TYPE | null>(null)

  return (
    <>
      <div
        css={[
          sectionContent,
          css`
            background: ${COLOR.BLACK_ALPHA_300};
            border-radius: 20px;
            padding: 24px;
            margin: 24px 0;
          `,
        ]}
      >
        {VIEW_ACCOUNT_TEXT.WALLET_ACCOUNTS_NOTES.map((note, index) => (
          <TextParagraph key={index} css={sectionListText}>
            {note}
          </TextParagraph>
        ))}
        <ol css={[sectionContent, sectionList]}>
          {VIEW_ACCOUNT_TEXT.WALLET_ACCOUNTS_STEPS.map((step, index) => (
            <li key={index} css={sectionListText}>
              <TextParagraph css={sectionListText}>{step}</TextParagraph>
            </li>
          ))}
        </ol>
      </div>

      {selectedCoinType == null ? (
        <WalletAccountsLists setSelectedCoinType={setSelectedCoinType} />
      ) : (
        <div css={container}>
          <div css={containerHeader}>
            <BackButton onClick={() => setSelectedCoinType(null)}>
              <BackIcon css={backIcon} />
              <TextSpan css={backText}>{VIEW_ACCOUNT_TEXT.BACK}</TextSpan>
            </BackButton>
            <div
              css={css`
                display: flex;
                align-items: center;
                gap: 6px;
                margin-left: 12px;
              `}
            >
              <AssetIcon
                asset={selectedCoinType}
                css={css`
                  width: 24px;
                  height: 24px;
                `}
              />
              <TextParagraph
                css={css`
                  color: ${COLOR.WHITE};
                  font-size: 16px;
                  font-weight: 700;
                `}
              >
                {VIEW_ACCOUNT_TEXT.DEPOSIT(selectedCoinType)}
              </TextParagraph>
            </div>
          </div>
          <WalletAcountDepositAddress coinType={selectedCoinType} />
        </div>
      )}
    </>
  )
}

function WalletAccountsLists({
  setSelectedCoinType,
}: {
  setSelectedCoinType: (coinType: COIN_TYPE) => void
}) {
  return (
    <>
      <div css={accountsSection}>
        <TextParagraph css={sectionHeader}>
          {VIEW_ACCOUNT_TEXT.BITCOIN_NETWORK}
        </TextParagraph>
        <div css={accountsList}>
          {Object.values([COIN_TYPE.BTC]).map((coinType) => (
            <WalletAccountRow
              key={coinType}
              coinType={coinType}
              onClick={() => setSelectedCoinType(coinType)}
            />
          ))}
        </div>
      </div>

      <div css={accountsSection}>
        <TextParagraph css={sectionHeader}>
          {VIEW_ACCOUNT_TEXT.ETHEREUM_NETWORK}
        </TextParagraph>
        <div css={accountsList}>
          {Object.values([COIN_TYPE.ETH]).map((coinType) => (
            <WalletAccountRow
              key={coinType}
              coinType={coinType}
              onClick={() => setSelectedCoinType(coinType)}
            />
          ))}
        </div>
      </div>
    </>
  )
}

function WalletAcountDepositAddress({ coinType }: { coinType: COIN_TYPE }) {
  const {
    data,
    error,
    loading,
    fetch: fetchNextDepositAddress,
  } = useApi<{ address: string }>({
    serviceName: 'trade',
    path: 'tradeAccounts/nextDepositAddress',
    method: 'GET',
  })

  useEffect(() => {
    void fetchNextDepositAddress({
      query: {
        asset: coinType,
      },
    })
  }, [coinType, fetchNextDepositAddress])

  if (loading) {
    return <DepositAddressLoader />
  }

  if (error != null) {
    return (
      <TextParagraph css={errorText}>{VIEW_ACCOUNT_TEXT.ERROR}</TextParagraph>
    )
  }

  if (data == null) {
    return <TextParagraph>{VIEW_ACCOUNT_TEXT.NO_ADDRESS}</TextParagraph>
  }

  const start = data.address.slice(0, 5)
  const middle = data.address.slice(5, -5)
  const end = data.address.slice(-5)

  return (
    <div css={tabContainerStyles}>
      <div css={tabSectionCenterStyles}>
        <QrCode value={data.address} />
        <TextCode
          css={css`
            color: ${COLOR.WHITE};
            font-size: 14px;
            letter-spacing: 0.1em;
            line-height: 1.6;

            text-align: center;
            max-width: 32ch;
          `}
        >
          <span css={{ color: COLOR.PURPLE_300 }}>{start}</span>
          <span>
            {middle
              .split(/(\w{4})/)
              .filter(Boolean)
              .map((part, index) => (
                <Fragment key={index}>&ensp;{part}</Fragment>
              ))}
          </span>
          &ensp;
          <span css={{ color: COLOR.PURPLE_300 }}>{end}</span>
        </TextCode>
      </div>
    </div>
  )
}

function ViewAccountLoader() {
  return (
    <div css={tabContainerStyles}>
      <div css={tabSectionCenterStyles}>
        <SkeletonLoader dark={true} width={160} height={80} borderRadius={24} />
      </div>
      <SkeletonLoader dark={true} height={24} borderRadius={6} width={80} />
      <SkeletonLoader dark={true} height={56} borderRadius={12} />
      <SkeletonLoader dark={true} height={56} borderRadius={12} />
    </div>
  )
}

function DepositAddressLoader() {
  return (
    <div css={tabContainerStyles}>
      <div css={tabSectionCenterStyles}>
        <SkeletonLoader
          dark={true}
          width={172}
          height={172}
          borderRadius={18}
        />
        <SkeletonLoader dark={true} height={44} borderRadius={12} width={240} />
      </div>
    </div>
  )
}

function AccountIcon({ account }: { account: Account }) {
  if (account.type === AccountType.EXTERNAL && account.image != null) {
    return <BankAccountImage src={`data:image/png;base64,${account.image}`} />
  }

  return <AssetIcon asset={account.asset} />
}

export function BankAccountAddressMask({
  address,
}: {
  address?: string | null
}) {
  return (
    <TextCode>
      {/* eslint-disable-next-line react/jsx-no-literals */}
      <TextCode>＊＊＊</TextCode>
      <TextCode
        css={css`
          letter-spacing: -0.5em;
        `}
      >
        &nbsp;
      </TextCode>
      <TextCode
        css={css`
          letter-spacing: 0.125em;
        `}
      >
        {address ?? ''}
      </TextCode>
    </TextCode>
  )
}

const container = css`
  padding: 24px;
  background: ${COLOR.WHITE_ALPHA_50};
  border-radius: 12px;
`

const containerHeader = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 16px;
`

export const totalValueAmount = css`
  color: ${COLOR.WHITE};
  font-size: 20px;
  font-weight: 600;
`

export const sectionTitle = css`
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16 px;
`

const accountRowContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid ${COLOR.WHITE_ALPHA_100};

  &:last-child {
    border-bottom: none;
  }
`

const assetText = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  font-weight: 500;
`

const balanceContainer = css`
  display: flex;
  flex-direction: column;
  text-align: right;
`

const balanceText = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  font-weight: 500;
`

const errorText = css`
  color: ${COLOR.RED_500};
  text-align: center;
`

const digitalBalanceContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 24px 0;
`

const balanceLabel = css`
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
  margin-bottom: 8px;
`

const poweredByText = css`
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 12px;
  margin-top: 8px;
`

const assetContainer = css`
  display: flex;
  align-items: center;
  gap: 12px;
`

const AssetIcon = styled.div<{ asset: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${COLOR.WHITE_ALPHA_100};

  ${({ asset }) => FIAT_ASSETS.includes(asset) && cashIcon}
  ${({ asset }) => BTC_COIN_TYPES.includes(asset as COIN_TYPE) && btcIcon}
  ${({ asset }) => ETH_COIN_TYPES.includes(asset as COIN_TYPE) && ethIcon}
`

const cashIcon = css`
  background: ${COLOR.BLUE_500};
`

const btcIcon = css`
  background: #ff9900;
`

const ethIcon = css`
  background: #627eeb;
`

const rowButton = css`
  width: 100%;

  &:hover,
  &:focus {
    background: ${COLOR.WHITE_ALPHA_50};
  }
`

const fiatBalanceText = css`
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
`

const accountsList = css`
  background: ${COLOR.WHITE_ALPHA_50};
  border-radius: 12px;
  overflow: hidden;
`

const currencyTitle = css`
  color: ${COLOR.WHITE};
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin: 16px 0;
`

const subBalance = css`
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 14px;
  margin-top: 8px;
`

const BackButton = styled(PlainButton)`
  background: ${COLOR.BLACK_ALPHA_300};
  display: flex;
  padding: 8px;
  border-radius: 8px;

  &:hover,
  &:focus {
    background: ${COLOR.BLACK_ALPHA_500};
  }
`

const backIcon = css`
  width: 16px;
  height: 16px;
  margin-right: 6px;
  path {
    fill: ${COLOR.WHITE_ALPHA_800};
  }
`

const backText = css`
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 14px;
  font-weight: 700;
`

const accountsSection = css`
  margin-bottom: 24px;
`

const cashAccountsContainer = css`
  background: ${COLOR.WHITE_ALPHA_50};
  border-radius: 12px;
  overflow: hidden;
  margin-top: 8px;
`

const sectionHeader = css`
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`

const sectionContent = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const sectionList = css`
  padding-left: 1.5em;
`

const sectionListText = css`
  ${baseTextStyles}
  font-size: 14px;
  color: ${COLOR.WHITE_ALPHA_800};
`

const tabContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 24px 0;
`

const tabSectionCenterStyles = css`
  align-self: center;
  align-items: center;

  display: flex;
  flex-direction: column;
  gap: 16px;
`
