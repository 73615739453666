import { css } from '@emotion/react'
import { Redirect } from 'react-router-dom'

import Page, { PageTitle } from 'src/components/Page'
import Spacer from 'src/components/Spacer'
import COLOR from 'src/constants/color'
import ROUTE from 'src/constants/route'
import { TradeCardTips } from 'src/features/trade/components/DemoCard'
import { Step, Steps, TradeStep } from 'src/features/trade/components/DemoStep'
import FundAccount, {
  FUND_ACCOUNT_TEXT,
} from 'src/features/trade/components/FundAccount'
import SetupAccount from 'src/features/trade/components/SetupAccount'
import {
  SETUP_ACCOUNT_TITLE,
  SETUP_ACCOUNT_TIPS,
} from 'src/features/trade/components/SetupAccount'
import {
  TradeBuy,
  SelectBuyAsset,
} from 'src/features/trade/components/TradeBuy'
import { TRADE_BUY_TEXT } from 'src/features/trade/components/TradeBuy'
import TradeSandbox, {
  TRADE_SANDBOX_TEXT,
} from 'src/features/trade/components/TradeSandbox'
import { TradeWithdraw } from 'src/features/trade/components/TradeWithdraw'
import { TRADE_WITHDRAW_TEXT } from 'src/features/trade/components/TradeWithdraw'
import ViewAccount from 'src/features/trade/components/ViewAccount'
import { TradeProvider } from 'src/features/trade/hooks/useTrade'
import useFeatureFlags from 'src/hooks/useFeatureFlags'

/**
 * Constants for the Buy Page
 */
const BUY_PAGE_CONSTANTS = {
  SPACER_UNITS: {
    TOP: 8,
    CONTENT: 4,
  },
  MAX_WIDTH: 1600,
  PADDING: 48,
} as const

const tradeSteps: TradeStep[] = [
  {
    title: SETUP_ACCOUNT_TITLE,
    leftContent: <SetupAccount />,
    rightContent: <TradeCardTips tips={SETUP_ACCOUNT_TIPS} />,
  },
  {
    title: FUND_ACCOUNT_TEXT.TITLE,
    leftContent: <FundAccount />,
    rightContent: (
      <>
        <TradeCardTips tips={[FUND_ACCOUNT_TEXT.TIP]} />
        <ViewAccount />
      </>
    ),
  },
  {
    title: TRADE_BUY_TEXT.TITLE,
    leftContent: <SelectBuyAsset />,
    rightContent: (
      <>
        <TradeCardTips tips={TRADE_BUY_TEXT.TIPS} />
        <TradeBuy />
      </>
    ),
  },
  {
    title: TRADE_WITHDRAW_TEXT.TITLE,
    leftContent: <ViewAccount />,
    rightContent: (
      <>
        <TradeCardTips tips={TRADE_WITHDRAW_TEXT.TIPS} />
        <TradeWithdraw />
      </>
    ),
  },
]

function SandboxTestingStep() {
  return (
    <Step
      emphasis='blue'
      title={TRADE_SANDBOX_TEXT.TITLE}
      leftContent={<ViewAccount />}
      rightContent={
        <>
          <TradeCardTips tips={TRADE_SANDBOX_TEXT.TIPS} />
          <TradeSandbox />
        </>
      }
    />
  )
}

/**
 * Renders the main content of the buy page, including setup and funding steps
 */
function BuyPageContent() {
  const { featureFlags, loading: featureFlagsLoading } = useFeatureFlags()

  if (!featureFlagsLoading && !featureFlags.enableWebBuySell) {
    return <Redirect to={ROUTE.HOME} />
  }

  return (
    <Page
      documentTitle={FUND_ACCOUNT_TEXT.BUY_CRYPTO}
      background={COLOR.BLACK}
      layout='full-page'
    >
      <div css={flowContainer}>
        <Spacer unit={BUY_PAGE_CONSTANTS.SPACER_UNITS.TOP} />
        <PageTitle css={pageText}>{FUND_ACCOUNT_TEXT.BUY_CRYPTO}</PageTitle>
        <Spacer unit={BUY_PAGE_CONSTANTS.SPACER_UNITS.CONTENT} />
        <Steps steps={tradeSteps} />
        <Spacer unit={BUY_PAGE_CONSTANTS.SPACER_UNITS.CONTENT} />
        <SandboxTestingStep />
      </div>

      {/*
        Add spacing at the bottom to prevent the page from jumping
        when switching between tabs in the last step.
      */}
      <Spacer unit={64} />
    </Page>
  )
}

/**
 * Main Buy Page component wrapped with TradeProvider
 */
export default function BuyPage() {
  return (
    <TradeProvider>
      <BuyPageContent />
    </TradeProvider>
  )
}

const pageText = css`
  color: ${COLOR.WHITE};
`

const flowContainer = css`
  padding: ${BUY_PAGE_CONSTANTS.PADDING}px;
  max-width: ${BUY_PAGE_CONSTANTS.MAX_WIDTH}px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`
