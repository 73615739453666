import { createContext, useContext, ReactNode, useState } from 'react'

import useTradeBuy, {
  TradeBuyState,
} from 'src/features/trade/hooks/useTradeBuy'
import useTradeFunding, {
  TradeFundingState,
} from 'src/features/trade/hooks/useTradeFunding'
import useTradeOnboarding, {
  TradeOnboardingState,
} from 'src/features/trade/hooks/useTradeOnboarding'
import {
  TradeWithdrawState,
  useTradeWithdraw,
} from 'src/features/trade/hooks/useTradeWithdraw'

export enum TradeUserStatus {
  NOT_STARTED = 'not_started',
  INCOMPLETE = 'incomplete',
  PENDING = 'pending',
  ATTEMPT_FAILED = 'attempt_failed',
  FAILED = 'failed',
  UNAVAILABLE = 'unavailable',
  ACTIVE = 'active',
  CLOSED = 'closed',
  DISABLED = 'disabled',
  LOCKED = 'locked',
}

export enum TradeUserProvider {
  ZERO_HASH = 'zh',
}

export interface TradeUser {
  id: string
  email: string
  status: TradeUserStatus
  provider?: TradeUserProvider
  providerUserId?: string
}

interface TradeContextType {
  tradeUser: TradeUser | null
  setTradeUser: (user: TradeUser | null) => void
  fundingState: TradeFundingState
  onboardingState: TradeOnboardingState
  buyState: TradeBuyState
  withdrawState: TradeWithdrawState
}

const TradeContext = createContext<TradeContextType | undefined>(undefined)

export function TradeProvider({ children }: { children: ReactNode }) {
  const [tradeUser, setTradeUser] = useState<TradeUser | null>(null)
  const onboardingState = useTradeOnboarding({ tradeUser, setTradeUser })
  const fundingState = useTradeFunding({ tradeUser })
  const buyState = useTradeBuy()
  const withdrawState = useTradeWithdraw()

  return (
    <TradeContext.Provider
      value={{
        tradeUser,
        setTradeUser,
        onboardingState,
        fundingState,
        buyState,
        withdrawState,
      }}
    >
      {children}
    </TradeContext.Provider>
  )
}

export function useTrade() {
  const context = useContext(TradeContext)
  if (context === undefined) {
    throw new Error('useTradeContext must be used within a TradeProvider')
  }
  return context
}
