import { css, SerializedStyles } from '@emotion/react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import COLOR from 'src/constants/color'

export default function SkeletonLoader({
  width,
  height,
  borderRadius,
  dark,
}: {
  width?: string | number
  height?: string | number
  borderRadius?: string | number
  dark?: boolean
}): React.ReactElement {
  return (
    <Skeleton
      duration={1.25}
      width={width}
      height={height}
      borderRadius={borderRadius}
      baseColor={dark === true ? COLOR.GRAY_800 : COLOR.GRAY_100}
      highlightColor={
        dark === true ? `${COLOR.GRAY_600}80` : `${COLOR.GRAY_50}80`
      }
      style={dark === true ? { opacity: 0.25 } : undefined}
    />
  )
}

/**
 * Skeleton loaders can be problematic for z-index stacking contexts, for
 * safer-display, use StaticLoader in place of SkeletonLoader.
 */
export function StaticLoader({
  width,
  height,
  borderRadius,
  dark,
  elementCss,
}: {
  width?: string
  height?: string
  borderRadius?: string
  dark?: boolean
  elementCss?: SerializedStyles
}): React.ReactElement {
  return (
    <div
      css={[
        css`
          width: ${width ?? 'auto'};
          height: ${height ?? 'auto'};
          border-radius: ${borderRadius ?? '18px'};
          background-color: ${dark === true ? COLOR.GRAY_800 : COLOR.GRAY_100};
        `,
        elementCss,
      ]}
    />
  )
}
