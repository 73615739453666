import createRenderer from 'basic-parser';
import qs from 'qs';
import { RESPONSE_STATUS } from 'src/constants/api';
import CONFIG from 'src/constants/config';
import { isRecord } from 'src/utils/object'; // TODO: replace with a parser that is purpose built for this
const render = createRenderer([{ match: { start: '\\{', end: '\\}' }, renderMatch: _ref => { let { children, data } = _ref; if (children.length !== 1 || typeof children[0] !== 'string') {
            throw new Error('Invalid variable name');
        } const variableName = children[0]; if (data == null) {
            throw new Error('No data passed to interpolate');
        } const variableValue = data[variableName]; if (typeof variableValue !== 'string') {
            throw new Error('Invalid variable value');
        } return variableValue; } }]);
async function apiFetch(_ref2) {
    var _headers$Accept;
    let { token, origin, path, pathData, method = 'GET', credentials, headers = {}, body, query, mode } = _ref2;
    const finalPath = pathData != null ? render(path, pathData).join('') : path;
    const params = qs.stringify(query, { arrayFormat: 'repeat', encodeValuesOnly: true });
    const endpoint = "".concat(origin, "/").concat(finalPath, "?").concat(params); // If a token is provided, add it to the headers. WARNING: This will overwrite any existing Authorization header.
    if (token != null) {
        headers.Authorization = "Bearer ".concat(token);
    }
    if (headers['Content-Type'] === '') {
        delete headers['Content-Type'];
    }
    else if (!(body instanceof FormData)) { // Allow explicit Content-Type override
        if (headers['Content-Type'] == null) {
            headers['Content-Type'] = 'application/json;charset=UTF-8';
        }
    }
    const response = await fetch(endpoint, { credentials, method, headers, mode, // prettier-ignore
        body: body == null ? undefined : body instanceof FormData ? body : JSON.stringify(body) });
    if (!response.ok) { // eslint-disable-next-line @typescript-eslint/no-empty-function
        const responseError = await response.json().catch(() => { }); // Handle maintenance mode (503) as a special case to allow the response to be
        // returned rather than marked as an error, specifically for endpoints like
        // `/application/status` that remain accessible during maintenance mode.
        if (response.status === RESPONSE_STATUS.MAINTENANCE && isRecord(responseError) && responseError.error == null) {
            return { status: response.status, result: responseError, error: null };
        }
        const error = normalizeResponseError(responseError != null ? responseError : response.statusText);
        console.error('Error result from %o: %o', endpoint, error);
        return { status: response.status, result: null, error };
    }
    if (((_headers$Accept = headers.Accept) === null || _headers$Accept === void 0 ? void 0 : _headers$Accept.startsWith('text/')) === true) {
        const result = await response.text();
        return { status: response.status, error: null, result: result };
    }
    const result = response.status === RESPONSE_STATUS.NO_CONTENT ? null : await response.json();
    return { status: response.status, error: null, result };
} /**
 * Normalizes backend errors to a standard error interface with a message and
 * optionally an error code.
 */
function normalizeResponseError(responseError) {
    if (responseError == null) {
        return { message: 'An empty error was returned by the API' };
    } // normalize res.send('some error message')
    if (typeof responseError === 'string') {
        return { message: responseError };
    } // normalize res.send(123)
    if (!isRecord(responseError)) {
        var _Object$prototype$toS;
        return { message: "An unexpected error type was returned by the API: ".concat((_Object$prototype$toS = Object.prototype.toString.call(responseError).split(/\[object (\w+)\]/)[1]) !== null && _Object$prototype$toS !== void 0 ? _Object$prototype$toS : '') };
    } // normalize res.json({ error: 'some error message' })
    if (typeof responseError.error === 'string') {
        return { message: responseError.error, code: typeof responseError.code === 'string' ? responseError.code : undefined };
    } // normalize res.json({ error: { message: 'some error message' } })
    if (isRecord(responseError.error)) {
        return { message: typeof responseError.error.message === 'string' ? responseError.error.message : 'Unknown error', code: typeof responseError.error.code === 'string' ? responseError.error.code : undefined };
    } // normalize res.json({ message: 'some error message' })
    return { message: typeof responseError.message === 'string' ? responseError.message : 'Unknown error', code: typeof responseError.code === 'string' ? responseError.code : undefined };
}
export async function sendServiceRequest(_ref3) {
    let { serviceName, path, pathData, method, headers, body, query, token, credentials = 'include', fetchMode = 'cors' } = _ref3; // prettier-ignore
    let origin = serviceName === 'vault' ? CONFIG.VAULT_API_ORIGIN : serviceName === 'commerce' ? CONFIG.COMMERCE_API_ORIGIN : serviceName === 'trade' ? CONFIG.TRADE_API_ORIGIN : CONFIG.GATEWAY_API_ORIGIN; // If we're in development mode, use the proxies defined in setupProxy.js
    if (CONFIG.NODE_ENV === 'development') {
        origin = origin === CONFIG.GATEWAY_API_ORIGIN ? '/gateway' : "/".concat(serviceName);
    } // prettier-ignore
    const prefixedPath = path === 'auth/local' ? path : serviceName === 'vault' || serviceName === 'commerce' || serviceName === 'trade' ? "api/".concat(path) : "".concat(serviceName, "/api/").concat(path);
    return await apiFetch({ token, origin, path: prefixedPath, pathData, method, credentials, mode: fetchMode, headers: { Accept: 'application/json', 'Accept-Encoding': 'gzip', // apply compression by default
            ...headers }, body, query });
}
