import { css, keyframes } from '@emotion/react'

import COLOR from 'src/constants/color'

interface LoaderProps {
  color?: COLOR
  ['data-testid']?: string
}

const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`

const ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(14px, 0);
  }
`

export function DotsLoader({
  color,
  'data-testid': dataTestId,
}: LoaderProps): React.ReactElement {
  const circleStyles = css`
    position: absolute;
    top: 20px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${color ?? COLOR.PURPLE_400};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  `

  return (
    <div
      data-testid={dataTestId}
      css={css`
        display: flex;
        justify-content: center;
      `}
    >
      <div
        css={css`
          position: relative;
          width: 48px;
          height: 48px;
        `}
      >
        <div
          css={[
            circleStyles,
            css`
              left: 6px;
              animation: ${ellipsis1} 375ms 375ms infinite;
            `,
          ]}
        />
        <div
          css={[
            circleStyles,
            css`
              left: 6px;
              animation: ${ellipsis2} 375ms infinite;
            `,
          ]}
        />
        <div
          css={[
            circleStyles,
            css`
              left: 20px;
              animation: ${ellipsis2} 375ms infinite;
            `,
          ]}
        />
        <div
          css={[
            circleStyles,
            css`
              left: 34px;
              animation: ${ellipsis3} 375ms infinite;
            `,
          ]}
        />
      </div>
    </div>
  )
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

interface SpinningLoaderProps extends LoaderProps {
  size?: number
}

export function SpinningLoader({
  color = COLOR.GRAY_500,
  size,
  'data-testid': dataTestId,
}: SpinningLoaderProps): React.ReactElement {
  return (
    <div>
      <svg
        width='90'
        height='90'
        viewBox='0 0 90 90'
        fill='none'
        css={[
          css`
            animation: ${spin} 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;

            display: inline-flex;
            position: relative;
            width: 18px;
            height: 18px;
          `,
          size == null
            ? null
            : css`
                width: ${size}px;
                height: ${size}px;
              `,
        ]}
        data-testid={dataTestId}
      >
        <path
          d='M45 0C47.5057 0 49.5102 2.00454 49.5102 4.51022C49.5102 7.0159 47.5057 9.02045 45 9.02045C25.159 9.02045 9.02045 25.155 9.02045 45C9.02045 64.845 25.154 80.9796 45 80.9796C64.841 80.9796 80.9796 64.846 80.9796 45C80.9796 42.4943 82.9841 40.4898 85.4898 40.4898C87.9955 40.4898 90 42.4943 90 45C90 69.8523 69.8573 90 45 90C20.1437 90 0 69.8563 0 45C0 20.1437 20.1427 0 45 0ZM78.2508 20.6533C80.3516 19.35 83.0499 20.0527 84.3502 22.263C85.6466 24.3752 84.9476 27.088 82.7492 28.3954C81.9487 28.7998 81.2497 29 80.4492 29C78.8482 29 77.3487 28.1951 76.6498 26.8838C75.3534 24.6735 76.0524 21.8586 78.2508 20.6533ZM62.2508 4.65331C64.3516 3.34996 67.0499 4.05268 68.3502 6.26299C69.6466 8.3752 68.9476 11.088 66.7492 12.3954C65.9487 12.7998 65.2497 13 64.4492 13C62.8482 13 61.3487 12.1951 60.6498 10.8838C59.3534 8.6735 60.0524 5.85856 62.2508 4.65331Z'
          fill={color}
        />
      </svg>
    </div>
  )
}

const centerLoaderStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`

interface CenteredSpinningLoaderProps extends SpinningLoaderProps {
  layout?: 'stacked' | 'inline'
  gap?: number
  children?: React.ReactNode
}

export function CenteredSpinningLoader({
  layout = 'stacked',
  gap = 0,
  children,
  ...props
}: CenteredSpinningLoaderProps): React.ReactElement {
  return (
    <div
      css={[
        centerLoaderStyle,
        css`
          flex-direction: ${layout === 'stacked' ? 'column' : 'row'};
          gap: ${gap}px;
        `,
      ]}
    >
      <SpinningLoader {...props} />
      {children}
    </div>
  )
}
