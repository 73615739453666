/* eslint-disable react/jsx-no-literals */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { PlaidInstitution, PlaidLinkOnSuccess } from 'react-plaid-link'
import { usePlaidLink } from 'react-plaid-link'
import { PlaidLinkOptions } from 'react-plaid-link'
import { PlaidLinkOnSuccessMetadata } from 'react-plaid-link'

import Button from 'src/components/Button'
import { ExternalLink } from 'src/components/Link'
import Spacer from 'src/components/Spacer'
import { fontFamilies, TextParagraph } from 'src/components/Text'
import COLOR from 'src/constants/color'
import { BankAccountAddressMask } from 'src/features/trade/components/ViewAccount'
import { useTrade } from 'src/features/trade/hooks/useTrade'
import {
  Activity,
  ActivityStatus,
  ActivityType,
  ExternalAccount,
  FUND_ASSETS,
  FundOption,
} from 'src/features/trade/hooks/useTradeFunding'
import {
  descriptionText,
  inputGroupStyles,
  labelStyles,
  buttonRowStyles,
  subgroupButton,
  selectedSubgroupButton,
} from 'src/features/trade/styles/DemoStyles'
import { useFormatDate, useFormatTime } from 'src/hooks/useTranslate'
import { ReactComponent as BackIcon } from 'src/images/chevron-left.svg'

/**
 * Constants for component text and configuration
 */
export const FUND_ACCOUNT_TEXT = {
  BUY_CRYPTO: 'Buy Crypto',
  TITLE: 'Step 2: Fund your account',
  TIP: 'Once KYC is complete, tradeUsers will have a ZeroHash participant ID, stored as "providerUserId" in the TradeUser table, and status of active. Next, a user must fund a ZeroHash account to begin buying or selling.',
  BACK_TEXT: 'Back',
  CONTINUE: 'Continue',
  FUND_BUTTON_TEXT: 'Fund Account',
  NOT_IMPLEMENTED_TEXT: 'Not Implemented',
  SUCCESS_MESSAGE:
    'Funding request submitted. Funds may not appear instantly, especially if this is the first time funding for this currency, as ZeroHash must make a new currency account. Use the refresh button to check the status of your funding. In practice, this may be checked via polling for updates provided by ZeroHash webhooks.',
  AMOUNT: 'Amount',
  AMOUNT_PLACEHOLDER: 'Enter amount',
  LEGAL_NAME_PLACEHOLDER: 'Enter legal name',
  RECENT_ACTIVITY: 'Recent Activity',
  REFRESH_ACTIVITY: 'Refresh Activity',
  DEMO_OPTION: {
    TITLE: 'Send fake fiat funds',
    DESCRIPTION:
      'Available for demo purposes only. This is the fastest way to set up your account.',
  },
  BANK_OPTION: {
    TITLE: 'Connect bank account',
    DESCRIPTION:
      'Transfer funds into and out of your trading account via bank ACH transfers.',
  },
  ACH_OPTION: {
    TITLE: 'Deposit fiat via ACH',
    DESCRIPTION:
      'Connect a bank account first. Deposited funds show up instantly as pending. The balance is updated once the deposit is confirmed.',
    SELECT_BANK_ACCOUNT: 'Select bank account',
  },
  WIRE_OPTION: {
    TITLE: 'Deposit fiat via wire',
    DESCRIPTION:
      "You'll be notified when your account is ready, usually within 24-48 hours.",
  },
  CRYPTO_OPTION: {
    TITLE: 'Deposit crypto',
    DESCRIPTION:
      'Transfer crypto from your Casa wallet to fund your trading account.',
  },
  DEPOSIT: 'Deposited',
  PENDING: 'Pending',
  CANCELLED: 'Cancelled',
  FAILED: 'Failed',
  WITHDRAWAL: 'Withdrew',
  BUY: 'Bought',
  SELL: 'Sold',
  WIRE_INSTRUCTIONS: {
    TITLE: 'Wire Transfer Instructions',
    BANK: 'Bank',
    ACCOUNT_NUMBER: 'Account Number',
    ROUTING_NUMBER: 'Routing Number',
    MEMO: 'Memo',
    MINIMUM_DEPOSIT: 'Minimum Deposit',
    WIRE_TRANSFER_FEE: 'Wire Transfer Fee',
  },
} as const

const ACTIVITY_STATUS_DISPLAY = {
  [ActivityStatus.PENDING]: FUND_ACCOUNT_TEXT.PENDING,
  [ActivityStatus.PROPOSED]: FUND_ACCOUNT_TEXT.PENDING,
  [ActivityStatus.CANCELLED]: FUND_ACCOUNT_TEXT.CANCELLED,
  [ActivityStatus.COMPLETED]: null,
  [ActivityStatus.FAILED]: FUND_ACCOUNT_TEXT.FAILED,
} as Record<ActivityStatus, string | null>

const ACTIVITY_TYPE_DISPLAY = {
  [ActivityType.DEPOSIT]: FUND_ACCOUNT_TEXT.DEPOSIT,
  [ActivityType.WITHDRAWAL]: FUND_ACCOUNT_TEXT.WITHDRAWAL,
  [ActivityType.BUY]: FUND_ACCOUNT_TEXT.BUY,
  [ActivityType.SELL]: FUND_ACCOUNT_TEXT.SELL,
} as Record<ActivityType, string>

const fundOptionsToText = {
  [FundOption.DEMO]: {
    title: FUND_ACCOUNT_TEXT.DEMO_OPTION.TITLE,
    description: FUND_ACCOUNT_TEXT.DEMO_OPTION.DESCRIPTION,
  },
  [FundOption.BANK]: {
    title: FUND_ACCOUNT_TEXT.BANK_OPTION.TITLE,
    description: FUND_ACCOUNT_TEXT.BANK_OPTION.DESCRIPTION,
  },
  [FundOption.ACH]: {
    title: FUND_ACCOUNT_TEXT.ACH_OPTION.TITLE,
    description: FUND_ACCOUNT_TEXT.ACH_OPTION.DESCRIPTION,
  },
  [FundOption.WIRE]: {
    title: FUND_ACCOUNT_TEXT.WIRE_OPTION.TITLE,
    description: FUND_ACCOUNT_TEXT.WIRE_OPTION.DESCRIPTION,
  },
  [FundOption.CRYPTO]: {
    title: FUND_ACCOUNT_TEXT.CRYPTO_OPTION.TITLE,
    description: FUND_ACCOUNT_TEXT.CRYPTO_OPTION.DESCRIPTION,
  },
}

function FundOptionCard({
  option,
  title,
  description,
  isEnabled,
  onClick,
  children,
}: {
  option: FundOption
  title: string
  description: string
  isEnabled: boolean
  onClick: (option: FundOption) => void
  children?: React.ReactNode
}) {
  return (
    <div css={fundOptionCardContainer} onClick={() => onClick(option)}>
      <TextParagraph css={fundOptionTitle}>{title}</TextParagraph>
      <TextParagraph css={fundOptionDescription}>{description}</TextParagraph>
      {children}
    </div>
  )
}

export function ActivityList({
  activity,
  onRefresh,
  activityLoading,
}: {
  activity: Activity[]
  onRefresh: () => void
  activityLoading: boolean
}) {
  const formatDate = useFormatDate({
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
  const formatTime = useFormatTime()

  return (
    <div css={activityListContainer}>
      <div css={activityHeaderContainer}>
        <TextParagraph css={activityTitle}>
          {FUND_ACCOUNT_TEXT.RECENT_ACTIVITY}
        </TextParagraph>
        <Button
          size='small'
          variant='alternate-inverted'
          onClick={onRefresh}
          loading={activityLoading}
        >
          {FUND_ACCOUNT_TEXT.REFRESH_ACTIVITY}
        </Button>
      </div>
      {activity.map((item, index) => (
        <div key={`${item.asset}-${index}`} css={activityItemContainer}>
          <div css={activityIconContainer}>
            {/* Add an icon here if needed */}
          </div>
          <div css={activityDetailsContainer}>
            <TextParagraph css={activityType}>
              {ACTIVITY_TYPE_DISPLAY[item.type]} {item.asset}
            </TextParagraph>
            <TextParagraph css={activityTimestamp}>
              {formatDate(item.timestamp)} • {formatTime(item.timestamp)}
            </TextParagraph>
          </div>
          <div css={activityAmountContainer}>
            <TextParagraph css={activityAmount}>
              {item.amount.formatted}
            </TextParagraph>
            {ACTIVITY_STATUS_DISPLAY[item.status] != null && (
              <TextParagraph css={activityStatus(item.status)}>
                {ACTIVITY_STATUS_DISPLAY[item.status]}
              </TextParagraph>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

/**
 * Renders the funding option selection cards
 */
function FundOptionsList({
  onOptionSelect,
}: {
  onOptionSelect: (option: FundOption) => void
}) {
  return (
    <>
      {Object.entries(fundOptionsToText).map(
        ([option, { title, description }]) => (
          <FundOptionCard
            key={option}
            option={option as FundOption}
            title={title}
            description={description}
            isEnabled={option === FundOption.DEMO}
            onClick={() => onOptionSelect(option as FundOption)}
          />
        ),
      )}
    </>
  )
}

/**
 * Renders the funding form for demo accounts
 */
function DemoFundingForm({
  amount,
  selectedAsset,
  onAmountChange,
  onAssetChange,
  onSubmit,
  loading,
  showSuccess,
}: {
  amount: string
  selectedAsset: string
  onAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onAssetChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onSubmit: () => void
  loading: boolean
  showSuccess: boolean
}) {
  return (
    <>
      <div css={inputContainer}>
        <input
          type='number'
          value={amount}
          onChange={onAmountChange}
          placeholder={FUND_ACCOUNT_TEXT.AMOUNT_PLACEHOLDER}
          css={inputField}
        />
        <select
          value={selectedAsset}
          onChange={onAssetChange}
          css={assetSelect}
        >
          {FUND_ASSETS.map((asset) => (
            <option key={asset} value={asset}>
              {asset}
            </option>
          ))}
        </select>
      </div>
      <Button
        size='small'
        variant='alternate-inverted'
        onClick={onSubmit}
        loading={loading}
        css={actionButton}
      >
        {FUND_ACCOUNT_TEXT.FUND_BUTTON_TEXT}
      </Button>
      {showSuccess && (
        <TextParagraph css={successMessage}>
          {FUND_ACCOUNT_TEXT.SUCCESS_MESSAGE}
        </TextParagraph>
      )}
    </>
  )
}

export function PlaidLinkComponent({
  linkToken,
  onSuccess,
  onExit,
}: {
  linkToken: string
  onSuccess: (
    publicToken: string,
    metadata: PlaidLinkOnSuccessMetadata & { account_id: string },
  ) => void
  onExit: PlaidLinkOptions['onExit']
}) {
  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: onSuccess as PlaidLinkOnSuccess,
    onExit,
  })

  useEffect(() => {
    if (ready) {
      open()
    }
  }, [open, ready])

  return null
}

function BankConnectOption() {
  const { fundingState } = useTrade()

  const handleSuccess = async (
    publicToken: string,
    metadata: {
      accounts: { id: string }[]
      institution: PlaidInstitution | null
    },
  ) => {
    console.log('Plaid publicToken generated %o', metadata)
    await fundingState.screenTradeAccounts({
      publicToken,
      externalBankAccountIds: metadata.accounts.map((account) => account.id),
      externalBankAccountInstitutionId: metadata.institution?.institution_id,
    })

    const tradeAccountId = await fundingState.createTradeAccounts({
      publicToken,
      externalBankAccountIds: metadata.accounts.map((account) => account.id),
      externalBankAccountInstitutionId: metadata.institution?.institution_id,
    })

    if (tradeAccountId == null) {
      console.error('Failed to create Plaid tradeAccount')
      return
    }
    console.log('Plaid tradeAccount created %o', tradeAccountId)
  }

  const handleExit = () => {
    console.log('Exited Plaid flow')
    fundingState.clearLinkToken()
  }

  const handleCreateLinkToken = async () => {
    await fundingState.createLinkToken()
    console.log('Plaid linkToken generated')
  }

  return (
    <>
      {fundingState.connectBank.linkToken != null && (
        <PlaidLinkComponent
          linkToken={fundingState.connectBank.linkToken}
          onSuccess={handleSuccess}
          onExit={handleExit}
        />
      )}
      <Button
        size='small'
        variant='alternate-inverted'
        onClick={handleCreateLinkToken}
        loading={
          fundingState.connectBank.step === 'linking' ||
          fundingState.connectBank.step === 'connecting'
        }
        css={css`
          width: 100%;
        `}
      >
        {FUND_ACCOUNT_TEXT.CONTINUE}
      </Button>
      <Spacer unit={4} />
      <TextParagraph css={descriptionText}>
        In the sandbox environment, use test credentials from{' '}
        <ExternalLink href='https://plaid.com/docs/sandbox/test-credentials/'>
          test credentials
        </ExternalLink>{' '}
        or{' '}
        <ExternalLink href='https://plaid.com/docs/sandbox/institutions/#institution-details-for-auth-testing'>
          institution details for auth testing
        </ExternalLink>
        .
      </TextParagraph>
      {fundingState.connectBank.step === 'connected' && (
        <>
          <TextParagraph css={successMessage}>
            Bank account connected successfully.
          </TextParagraph>
          <TextParagraph css={successMessage}>
            If your account is active, you can now fund your account with a bank
            ACH transfer.
          </TextParagraph>
          <TextParagraph css={successMessage}>
            If not, go to the Sandbox Testing section below to set the bank
            account verification status.
          </TextParagraph>
        </>
      )}
    </>
  )
}

function AchFundingForm({
  amount,
  onAmountChange,
  onSubmit,
  loading,
  showSuccess,
}: {
  amount: string
  onAmountChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit: (externalAccountId: string) => void
  loading: boolean
  showSuccess: boolean
}) {
  const { fundingState } = useTrade()
  const [selectedBankAccount, setSelectedBankAccount] =
    useState<ExternalAccount | null>(null)

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 16px;
      `}
    >
      <div css={inputGroupStyles}>
        <label css={labelStyles}>
          {FUND_ACCOUNT_TEXT.ACH_OPTION.SELECT_BANK_ACCOUNT}
        </label>
        <div css={buttonRowStyles}>
          {fundingState.bankAccounts.map((account, index) => (
            <button
              key={`${account.id}-${account.externalBankAccountMask}-${index}`}
              type='button'
              css={[
                subgroupButton,
                account.id === selectedBankAccount?.id &&
                  selectedSubgroupButton,
              ]}
              onClick={() => setSelectedBankAccount(account)}
            >
              <BankAccountAddressMask
                address={account.externalBankAccountMask}
              />
            </button>
          ))}
        </div>
      </div>

      <div css={inputGroupStyles}>
        <label css={labelStyles}>{FUND_ACCOUNT_TEXT.AMOUNT}</label>
        <input
          type='number'
          value={amount}
          onChange={onAmountChange}
          placeholder={FUND_ACCOUNT_TEXT.AMOUNT_PLACEHOLDER}
          css={inputField}
        />
      </div>

      <Button
        size='small'
        variant='alternate-inverted'
        onClick={() =>
          onSubmit(selectedBankAccount?.externalAccountId as string)
        }
        disabled={selectedBankAccount == null}
        loading={loading}
      >
        {FUND_ACCOUNT_TEXT.FUND_BUTTON_TEXT}
      </Button>

      {showSuccess && (
        <TextParagraph css={successMessage}>
          {FUND_ACCOUNT_TEXT.SUCCESS_MESSAGE}
        </TextParagraph>
      )}
    </div>
  )
}

export default function FundAccount() {
  const { fundingState } = useTrade()
  const {
    loading,
    error,
    fundOption,
    setFundOption,
    fundAccount,
    createDepositAch,
    wireInstructions,
    clearLinkToken,
  } = fundingState
  const [isOptionsVisible, setIsOptionsVisible] = useState(true)
  const [selectedAsset, setSelectedAsset] = useState('USD')
  const [amount, setAmount] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)

  const handleFundOptionClick = (option: FundOption) => {
    setFundOption(option)
    setIsOptionsVisible(false)
    setShowSuccess(false)
  }

  const handleBackClick = () => {
    setFundOption(null)
    setIsOptionsVisible(true)
    setShowSuccess(false)
    clearLinkToken()
  }

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value)
    setShowSuccess(false)
  }

  const handleAssetChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedAsset(e.target.value)
    setShowSuccess(false)
  }

  const handleFundAccount = async () => {
    await fundAccount({ asset: selectedAsset, amount })
    setAmount('')
    setShowSuccess(true)
  }

  const handleCreateDepositAch = async (externalAccountId: string) => {
    await createDepositAch({ externalAccountId, amount })
    setAmount('')
    setShowSuccess(true)
  }

  return (
    <div css={fundAccountContainer}>
      {isOptionsVisible ? (
        <FundOptionsList onOptionSelect={handleFundOptionClick} />
      ) : (
        <>
          <div css={backButtonContainer} onClick={handleBackClick}>
            <BackIcon css={backIcon} />
            <TextParagraph css={backText}>
              {FUND_ACCOUNT_TEXT.BACK_TEXT}
            </TextParagraph>
          </div>
          {fundOption != null && (
            <FundOptionCard
              option={fundOption}
              title={fundOptionsToText[fundOption].title}
              description={fundOptionsToText[fundOption].description}
              isEnabled={fundOption === FundOption.DEMO}
              onClick={handleFundOptionClick}
            >
              <Spacer unit={4} />
              {fundOption === FundOption.DEMO && (
                <DemoFundingForm
                  amount={amount}
                  selectedAsset={selectedAsset}
                  onAmountChange={handleAmountChange}
                  onAssetChange={handleAssetChange}
                  onSubmit={handleFundAccount}
                  loading={loading}
                  showSuccess={showSuccess}
                />
              )}
              {fundOption === FundOption.BANK && <BankConnectOption />}
              {fundOption === FundOption.ACH && (
                <AchFundingForm
                  amount={amount}
                  onAmountChange={handleAmountChange}
                  onSubmit={handleCreateDepositAch}
                  loading={loading}
                  showSuccess={showSuccess}
                />
              )}
            </FundOptionCard>
          )}
          {fundOption === FundOption.WIRE && wireInstructions != null && (
            <div css={fundOptionCardContainer}>
              <TextParagraph css={fundOptionTitle}>
                {FUND_ACCOUNT_TEXT.WIRE_INSTRUCTIONS.TITLE}
              </TextParagraph>
              <div css={wireInstructionsContainer}>
                <div css={wireInstructionItem}>
                  <span css={wireInstructionLabel}>
                    {FUND_ACCOUNT_TEXT.WIRE_INSTRUCTIONS.BANK}
                  </span>
                  <span css={wireInstructionValue}>
                    {wireInstructions.bankName}
                  </span>
                </div>
                <div css={wireInstructionItem}>
                  <span css={wireInstructionLabel}>
                    {FUND_ACCOUNT_TEXT.WIRE_INSTRUCTIONS.ACCOUNT_NUMBER}
                  </span>
                  <span css={wireInstructionValue}>
                    {wireInstructions.accountNumber}
                  </span>
                </div>
                <div css={wireInstructionItem}>
                  <span css={wireInstructionLabel}>
                    {FUND_ACCOUNT_TEXT.WIRE_INSTRUCTIONS.ROUTING_NUMBER}
                  </span>
                  <span css={wireInstructionValue}>
                    {wireInstructions.routingNumber}
                  </span>
                </div>
                <div css={wireInstructionItem}>
                  <span css={wireInstructionLabel}>
                    {FUND_ACCOUNT_TEXT.WIRE_INSTRUCTIONS.MEMO}
                  </span>
                  <span css={wireInstructionValue}>
                    {wireInstructions.memo}
                  </span>
                </div>
                <div css={wireInstructionItem}>
                  <span css={wireInstructionLabel}>
                    {FUND_ACCOUNT_TEXT.WIRE_INSTRUCTIONS.MINIMUM_DEPOSIT}
                  </span>
                  <span css={wireInstructionValue}>
                    {wireInstructions.minimumDeposit.formatted}
                  </span>
                </div>
                <div css={wireInstructionItem}>
                  <span css={wireInstructionLabel}>
                    {FUND_ACCOUNT_TEXT.WIRE_INSTRUCTIONS.WIRE_TRANSFER_FEE}
                  </span>
                  <span css={wireInstructionValue}>
                    {wireInstructions.wireTransferFeeDomestic.formatted}
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {error != null && <TextParagraph css={errorText}>{error}</TextParagraph>}
    </div>
  )
}

const inputContainer = css`
  margin-bottom: 16px;
  display: flex;
  gap: 12px;
  align-items: center;
`

const inputField = css`
  flex: 1;
  height: 40px;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  background: transparent;
  color: ${COLOR.WHITE_ALPHA_800};
  font-family: ${fontFamilies};

  &::placeholder {
    color: ${COLOR.WHITE_ALPHA_400};
    font-family: ${fontFamilies};
  }
`

const assetSelect = css`
  height: 40px;
  padding: 8px 32px 8px 12px;
  border-radius: 4px;
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  background: transparent;
  color: ${COLOR.WHITE_ALPHA_800};
  font-family: ${fontFamilies};
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='4' viewBox='0 0 8 4'%3E%3Cpath fill='%23ffffff' d='M0 0l4 4 4-4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
`

const actionButton = css`
  margin-right: 12px;
`

const successMessage = css`
  margin-top: 16px;
  color: ${COLOR.GREEN_500};
  font-size: 14px;
`

const fundAccountContainer = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const backButtonContainer = css`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
`

const backIcon = css`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: ${COLOR.WHITE_ALPHA_800};
`

const backText = css`
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 14px;
  margin: 0;
`

const fundOptionCardContainer = css`
  background: ${COLOR.WHITE_ALPHA_50};
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  border-radius: 12px;
  padding: 16px;

  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${COLOR.WHITE_ALPHA_100};
  }
`

const fundOptionTitle = css`
  color: ${COLOR.WHITE_ALPHA_800};
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: 600;
`

const fundOptionDescription = css`
  margin: 0;
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
`

const activityListContainer = css`
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid ${COLOR.WHITE_ALPHA_100};
`

const activityHeaderContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const activityTitle = css`
  margin: 0;
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 16px;
  font-weight: 600;
`

const activityItemContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid ${COLOR.WHITE_ALPHA_50};

  &:last-child {
    border-bottom: none;
  }
`

const activityIconContainer = css`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: ${COLOR.WHITE_ALPHA_100};
  margin-right: 12px;
`

const activityDetailsContainer = css`
  flex: 1;
`

const activityType = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  font-weight: 500;
`

const activityAmountContainer = css`
  text-align: right;
`

const activityAmount = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  font-weight: 500;
`

const activityStatus = (status: ActivityStatus) => css`
  color: ${status === ActivityStatus.FAILED ? COLOR.RED_500 : COLOR.YELLOW_500};
  font-size: 12px;
  margin-top: 4px;
`

const activityTimestamp = css`
  margin: 0;
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
`

const errorText = css`
  margin-top: 16px;
  color: ${COLOR.RED_500};
  font-size: 12px;
`

const wireInstructionsContainer = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
`

const wireInstructionItem = css`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-family: ${fontFamilies};
  gap: 8px;
`

const wireInstructionLabel = css`
  color: ${COLOR.WHITE_ALPHA_600};
  text-transform: uppercase;
  font-size: 14px;
`

const wireInstructionValue = css`
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 14px;
`
