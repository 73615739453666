import { css } from '@emotion/react'

import { codeTextStyles, fontFamilies } from 'src/components/Text'
import COLOR from 'src/constants/color'

export const cardContainer = css`
  background: ${COLOR.WHITE_ALPHA_50};
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  border-radius: 12px;
  padding: 12px 24px 18px;
`

export const headingText = css`
  color: ${COLOR.WHITE_ALPHA_800};
  margin: 0 0 12px;
  font-size: 18px;
  font-weight: 600;
`

export const descriptionText = css`
  margin: 0 0 24px;
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;

  a {
    font-weight: inherit;
  }
  a > span {
    color: ${COLOR.PURPLE_01};
  }
`

export const errorText = css`
  margin-top: 16px;
  color: ${COLOR.RED_500};
  font-size: 12px;
`

export const digitalDisplayStyle = [
  codeTextStyles,
  css`
    font-size: 32px;
    font-weight: 500;
    color: ${COLOR.WHITE};
    letter-spacing: -0.5px;
  `,
]

export const formStyles = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const inputGroupStyles = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const labelStyles = css`
  color: ${COLOR.WHITE};
  font-size: 14px;
  font-family: ${fontFamilies};
`

export const inputStyles = css`
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  background: transparent;
  color: ${COLOR.WHITE};
  font-size: 16px;
  font-family: ${fontFamilies};

  &::placeholder {
    color: ${COLOR.WHITE_ALPHA_400};
  }
`

export const buttonRowStyles = css`
  display: flex;
  gap: 12px;
`

export const subgroupButton = css`
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  background: transparent;
  color: ${COLOR.WHITE};
  font-size: 14px;
  font-family: ${fontFamilies};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${COLOR.WHITE_ALPHA_50};
  }
`

export const selectedSubgroupButton = css`
  background: ${COLOR.WHITE_ALPHA_100};
  border-color: ${COLOR.WHITE};
`
