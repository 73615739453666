/* eslint-disable react/jsx-no-literals */
import { css } from '@emotion/react'
import { useState } from 'react'

import { ExternalLink } from 'src/components/Link'
import { TextCode } from 'src/components/Text'
import COLOR from 'src/constants/color'
import {
  StepActionButton,
  StepActionButtons,
  StepCard,
  StepErrorParagraph,
  StepParagraph,
} from 'src/features/trade/components/DemoStep'
import { AccountRow } from 'src/features/trade/components/ViewAccount'
import { useTrade } from 'src/features/trade/hooks/useTrade'
import {
  AccountStatus,
  BankAccountVerificationStatus,
  ExternalAccount,
} from 'src/features/trade/hooks/useTradeFunding'

export const TRADE_SANDBOX_TEXT = {
  TITLE: 'Sandbox Testing',
  TIPS: [
    'Simulate actions in the Plaid sandbox environment.',
    "The actions below will put the user into states as they would in a real environment outside of Casa's control.",
  ],
}

function SetBankVerificationStatus() {
  const { fundingState } = useTrade()
  const bankAccounts = fundingState.bankAccounts.filter(
    (bankAccount) => bankAccount.status === AccountStatus.PENDING,
  )

  const [isUpdating, setIsUpdating] = useState(new Set<string>())

  const updateStatus = async (
    bankAccount: ExternalAccount,
    status: BankAccountVerificationStatus,
  ) => {
    setIsUpdating((prev) => {
      prev.add(bankAccount.id)
      return new Set(prev)
    })
    await fundingState.updateBankAccountVerification(bankAccount.id, status)
    setIsUpdating((prev) => {
      prev.delete(bankAccount.id)
      return new Set(prev)
    })
  }

  if (bankAccounts.length === 0) {
    return (
      <StepParagraph
        css={css`
          font-style: italic;
        `}
      >
        No bank accounts pending verification.
      </StepParagraph>
    )
  }

  return (
    <>
      {fundingState.errors.setBankVerification != null && (
        <StepErrorParagraph>
          {fundingState.errors.setBankVerification}
        </StepErrorParagraph>
      )}
      {bankAccounts.map((bankAccount) => (
        <div key={bankAccount.id} css={subsectionCard}>
          <AccountRow account={bankAccount} />
          <StepActionButtons css={subsectionCardContent}>
            <StepActionButton
              loading={isUpdating.has(bankAccount.id)}
              onClick={() =>
                updateStatus(bankAccount, 'automatically_verified')
              }
            >
              Pass
            </StepActionButton>
            <StepActionButton
              disabled={isUpdating.has(bankAccount.id)}
              loading={isUpdating.has(bankAccount.id)}
              onClick={() => updateStatus(bankAccount, 'verification_expired')}
            >
              Fail
            </StepActionButton>
          </StepActionButtons>
        </div>
      ))}
    </>
  )
}

function ResetBankConnection() {
  const { fundingState } = useTrade()
  const bankAccounts = fundingState.bankAccounts.filter(
    (bankAccount) => bankAccount.status === AccountStatus.ACTIVE,
  )

  const [isResetting, setIsResetting] = useState(new Set<string>())

  const resetBankConnection = async (bankAccount: ExternalAccount) => {
    setIsResetting((prev) => {
      prev.add(bankAccount.id)
      return new Set(prev)
    })
    await fundingState.sandboxResetLogin(bankAccount.id)
    setIsResetting((prev) => {
      prev.delete(bankAccount.id)
      return new Set(prev)
    })
  }

  if (bankAccounts.length === 0) {
    return (
      <StepParagraph
        css={css`
          font-style: italic;
        `}
      >
        No active bank accounts.
      </StepParagraph>
    )
  }

  return (
    <>
      {fundingState.errors.resetBankConnection != null && (
        <StepErrorParagraph>
          {fundingState.errors.resetBankConnection}
        </StepErrorParagraph>
      )}
      {bankAccounts.map((bankAccount) => (
        <div key={bankAccount.id} css={subsectionCard}>
          <AccountRow account={bankAccount} />
          <StepActionButtons css={subsectionCardContent}>
            <StepActionButton
              onClick={() => resetBankConnection(bankAccount)}
              loading={isResetting.has(bankAccount.id)}
            >
              Force Reauthentication
            </StepActionButton>
          </StepActionButtons>
        </div>
      ))}
    </>
  )
}

export default function TradeSandbox() {
  const { fundingState } = useTrade()
  const { bankAccounts } = fundingState

  const [isTriggering, setIsTriggering] = useState(new Set<string>())

  const triggerWebhook = async (bankAccount: ExternalAccount, code: string) => {
    setIsTriggering((prev) => {
      prev.add(bankAccount.id)
      return new Set(prev)
    })
    await fundingState.sandboxTriggerWebhook(bankAccount.id, code)
    setIsTriggering((prev) => {
      prev.delete(bankAccount.id)
      return new Set(prev)
    })
  }

  const supportedWebhookCodes = [
    'PENDING_DISCONNECT',
    'USER_PERMISSION_REVOKED',
    'USER_ACCOUNT_REVOKED',
  ]

  return (
    <>
      {/* Empty space to have the grid start at the next cell */}
      <div />

      <StepCard
        title='Set Bank Verification Status'
        description={[
          'Sets the verification status of a bank account.',
          <>
            Read more details here:{' '}
            <ExternalLink href='https://plaid.com/docs/api/sandbox/#sandboxitemset_verification_status'>
              https://plaid.com/docs/api/sandbox/#sandboxitemset_verification_status
            </ExternalLink>
          </>,
        ]}
      >
        <SetBankVerificationStatus />
      </StepCard>

      <StepCard
        title='Reset Bank Connection'
        description={[
          'Forces a user to reauthenticate their bank account by going through the Plaid Link flow again.',
          <>
            Read more details here:{' '}
            <ExternalLink href='https://plaid.com/docs/api/sandbox/#sandboxuserreset_login'>
              https://plaid.com/docs/api/sandbox/#sandboxuserreset_login
            </ExternalLink>
          </>,
        ]}
      >
        <ResetBankConnection />
      </StepCard>

      <StepCard
        title='Fire Webhook'
        description={[
          'Triggers a webhook event (for us to listen to) as if it were sent from Plaid.',
          <>
            Read more details here:{' '}
            <ExternalLink href='https://plaid.com/docs/api/sandbox/#sandboxitemfire_webhook'>
              https://plaid.com/docs/api/sandbox/#sandboxitemfire_webhook
            </ExternalLink>
          </>,
        ]}
      >
        {fundingState.errors.triggerWebhook != null && (
          <StepErrorParagraph>
            {fundingState.errors.triggerWebhook}
          </StepErrorParagraph>
        )}

        {bankAccounts.map((bankAccount) => (
          <div key={bankAccount.id} css={subsectionCard}>
            <AccountRow account={bankAccount} />
            <StepActionButtons css={subsectionCardContent}>
              {supportedWebhookCodes.map((code) => (
                <StepActionButton
                  key={code}
                  loading={isTriggering.has(bankAccount.id)}
                  onClick={() => triggerWebhook(bankAccount, code)}
                >
                  <TextCode>{code}</TextCode>
                </StepActionButton>
              ))}
            </StepActionButtons>
          </div>
        ))}
      </StepCard>

      <StepCard
        title='(TODO) Bypass Plaid Link Flow'
        description='Generate a Public Token without going through the Plaid Link flow.'
      >
        <StepParagraph>
          Read more details here:{' '}
          <ExternalLink href='https://plaid.com/docs/api/sandbox/#sandboxpublic_tokencreate'>
            https://plaid.com/docs/api/sandbox/#sandboxpublic_tokencreate
          </ExternalLink>
        </StepParagraph>
      </StepCard>
    </>
  )
}

const subsectionCard = css`
  background: ${COLOR.BLACK_ALPHA_400};
  border-radius: 12px;
`

const subsectionCardContent = css`
  padding: 16px;
`
