import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Button from 'src/components/Button'
import PlainButton from 'src/components/PlainButton'
import {
  TextCode,
  TextH1,
  TextH3,
  TextParagraph,
  TextSpan,
} from 'src/components/Text'
import COLOR from 'src/constants/color'

/**
 * Represents a single step in a multi-step trade process
 */
export interface TradeStep {
  disabled?: boolean
  leftContent: React.ReactNode
  rightContent?: React.ReactNode
  title: string
  description?: string
  emphasis?: 'none' | 'blue'
}

interface StepsProps {
  steps: TradeStep[]
}

/**
 * Renders the documentation section of a step
 */
export function StepDocumentation({
  title,
  tips,
}: {
  title: string
  tips: React.ReactNode[]
}) {
  return (
    <div>
      <TextH1 css={documentationTitle}>{title}</TextH1>
      {tips.map((tip, index) => (
        <TextSpan key={`tip-${index}`} css={documentationText}>
          {tip}
        </TextSpan>
      ))}
    </div>
  )
}

/**
 * Renders a single step in the trade process
 */
export function Step({
  title,
  leftContent,
  rightContent,
  emphasis,
}: Pick<TradeStep, 'title' | 'leftContent' | 'rightContent' | 'emphasis'>) {
  return (
    <div css={[stepRow, emphasis === 'blue' && stepRowEmphasisBlue]}>
      <div css={leftColumn}>{leftContent}</div>
      <div css={rightColumn}>
        <div css={rightContentContainer}>
          <TextH3
            css={css`
              color: ${COLOR.WHITE};
              font-size: 20px;
            `}
          >
            {title}
          </TextH3>
          {rightContent != null && (
            <div css={rightContentSection}>{rightContent}</div>
          )}
        </div>
      </div>
    </div>
  )
}

const rightContentContainer = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const rightContentSection = css`
  flex: 1;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
`

/**
 * Steps component that renders a vertical list of trade process steps
 * Each step consists of a left content area (usually forms/interactions)
 * and an optional right content area (usually a demo or documentation)
 */
export function Steps({ steps }: StepsProps) {
  return (
    <div css={stepContainer}>
      {steps.map((step, index) => (
        <Step
          key={`step-${index}`}
          title={step.title}
          leftContent={step.leftContent}
          rightContent={step.rightContent}
        />
      ))}
    </div>
  )
}

export function StepCard({
  children,
  title,
  description,
  onClick,
}: {
  children?: React.ReactNode
  title: string
  description: string | React.ReactNode[]
  onClick?: () => void
}) {
  const Card = onClick ? PlainButton : 'div'
  const descriptionArray = Array.isArray(description)
    ? description
    : [description]
  return (
    <Card css={stepCard} onClick={onClick}>
      <div css={stepCardContent}>
        <TextH3 css={stepCardTitle}>{title}</TextH3>
        {descriptionArray.map((description, index) => (
          <StepParagraph key={`description-${index}`}>
            {description}
          </StepParagraph>
        ))}
      </div>
      {children != null && <div css={stepCardContent}>{children}</div>}
    </Card>
  )
}

export const StepParagraph = styled(TextParagraph)`
  margin: 0;
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;

  a {
    font-weight: inherit;
  }
  a,
  a > span {
    color: ${COLOR.PURPLE_01};
  }
  a:focus {
    outline-color: ${COLOR.PURPLE_01};
  }
`

export const StepErrorParagraph = styled(StepParagraph)`
  color: ${COLOR.PINK_500};
  font-weight: 500;
`

export const StepActionButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export const StepActionButton = styled(Button)`
  align-self: flex-start;

  height: 30px;
  padding: 6px 10px;
  border-radius: 8px;

  background: ${COLOR.WHITE_ALPHA_700};
  &:hover,
  &:focus {
    background: ${COLOR.WHITE_ALPHA_900};
  }

  text-transform: none;
  letter-spacing: 0;
  line-height: 1.25;

  font-size: 14px;
  font-weight: 600;
  ${TextCode} {
    font-weight: inherit;
    font-size: 12px;
    line-height: 13px;
  }
`
StepActionButton.defaultProps = {
  variant: 'alternate-inverted',
  size: 'small',
}

export const StepListRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${COLOR.BLACK_ALPHA_400};
  padding: 12px 16px;
  border-radius: 12px;
`

const stepCard = css`
  border: 1px solid ${COLOR.WHITE_ALPHA_100};
  border-radius: 12px;
  padding: 16px;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;

  background: ${COLOR.WHITE_ALPHA_50};
  &:hover,
  &:focus {
    background: ${COLOR.WHITE_ALPHA_100};
  }
`

export const stepCardTitle = css`
  color: ${COLOR.WHITE_ALPHA_800};
  font-size: 16px;
  font-weight: 600;
`

const stepCardContent = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`

const stepContainer = css`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: fit-content;
`

const stepRow = css`
  display: flex;
  position: relative;
  align-items: stretch;
  gap: 1rem;
  padding: 2rem;
  border: 1px solid ${COLOR.GRAY_800};
  background: ${COLOR.GRAY_900};
  border-radius: 16px;
  min-width: fit-content;
  overflow-x: auto;
`

const stepRowEmphasisBlue = css`
  border-color: ${COLOR.BLUE_900};
  background: ${COLOR.BLUE_900}1d;
`

const leftColumn = css`
  flex: 0 0 400px;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const rightColumn = css`
  flex: 1;
  padding-left: 2rem;
  border-left: 1px solid ${COLOR.GRAY_800};
`

const documentationTitle = css`
  color: ${COLOR.WHITE};
  font-size: 20px;
  margin-bottom: 16px;
`

const documentationText = css`
  color: ${COLOR.WHITE_ALPHA_600};
  font-size: 14px;
  line-height: 1.4;
  margin-bottom: 8px;
  white-space: pre-line;

  a > span {
    color: ${COLOR.PURPLE_01};
  }

  ul,
  ol {
    margin-top: 8px;
  }

  li {
    margin-bottom: 4px;
    line-height: 1.4;
  }
`
