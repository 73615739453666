import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'

import { TextSpan } from 'src/components/Text'
import COLOR from 'src/constants/color'
import { ELEMENT_HEIGHT, OUTLINE_THICKNESS } from 'src/constants/form-elements'

const HEIGHT = ELEMENT_HEIGHT.DEFAULT - 4
const BORDER_RADIUS = HEIGHT / 2

export interface PillTabOption<Value> {
  value: Value
  label: string
}

interface PillTabsProps<Value> {
  name: string
  options: readonly PillTabOption<Value>[]
  activeOption: PillTabOption<Value>
  setActiveOption: (option: PillTabOption<Value>) => void
  background?: string
  invertColors?: boolean
}

export default function PillTabs<Value extends string>({
  name,
  options,
  activeOption,
  setActiveOption,
  background,
  invertColors,
}: PillTabsProps<Value>): React.ReactElement {
  const activeOptionIndex = options.findIndex(
    (option) => option.value === activeOption.value,
  )

  const defaultBackground =
    invertColors === true ? COLOR.GRAY_900 : COLOR.GRAY_100

  return (
    <div
      css={css`
        background: ${background ?? defaultBackground};
        position: relative;

        height: ${HEIGHT}px;
        border-radius: ${BORDER_RADIUS}px;

        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(${options.length}, minmax(0, 1fr));
        align-items: center;
      `}
    >
      {options.map((option) => (
        <PillTab<Value>
          key={option.value}
          name={name}
          option={option}
          activeOption={activeOption}
          setActiveOption={setActiveOption}
          invertColors={invertColors}
        />
      ))}
      <ActivePill
        css={css`
          width: calc(100% / ${options.length});
          transform: translateX(calc(100% * ${activeOptionIndex}));
        `}
      />
    </div>
  )
}

const ActivePill = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  transition: 0.25s ease-out;

  background: ${COLOR.WHITE};
  border-radius: ${BORDER_RADIUS}px;
  border: 1px solid transparent;
  box-shadow: 0px 2px 8px ${COLOR.RICH_BLACK}18;
`

let id = 0

function PillTab<Value extends string>({
  name,
  option,
  activeOption,
  setActiveOption,
  invertColors,
}: {
  name: string
  option: PillTabOption<Value>
  activeOption: PillTabOption<Value>
  setActiveOption: (option: PillTabOption<Value>) => void
  invertColors?: boolean
}) {
  const [uniqueId] = useState(() => `PillTab-${option.value}-${++id}`)
  const checked = activeOption.value === option.value

  return (
    <>
      <input
        data-testid={`pill-tab-${option.value}`}
        type='radio'
        name={name}
        id={uniqueId}
        checked={checked}
        onChange={(event) => {
          if (event.currentTarget.checked) {
            setActiveOption(option)
          }
        }}
        css={css`
          position: absolute;
          opacity: 0;
          pointer-events: none;

          :focus ~ ${ActivePill} {
            border-color: ${COLOR.PURPLE_400}cc;
            box-shadow: 0px 2px 8px ${COLOR.RICH_BLACK}18,
              0 0 0 ${OUTLINE_THICKNESS}px ${COLOR.PURPLE_400}33;
          }
        `}
      />
      <label
        id={`${option.value}-pill-tab`}
        htmlFor={uniqueId}
        onClick={(event) => {
          event.preventDefault()
          event.currentTarget?.control?.click()
        }}
        css={css`
          position: relative;
          z-index: 2;
          height: 100%;

          display: flex;
          align-items: center;
          justify-content: center;

          text-align: center;
          cursor: pointer;
        `}
      >
        <TextSpan
          css={[
            css`
              display: flex;
              justify-content: center;
              align-self: center;

              font-size: 13px;
              font-weight: 700;
              text-transform: uppercase;

              transition: 0.25s ease-out;
            `,
            invertColors === true &&
              css`
                color: ${COLOR.WHITE};
              `,
            checked &&
              css`
                color: ${invertColors === true
                  ? COLOR.BLACK
                  : COLOR.PURPLE_400};
              `,
          ]}
        >
          {option.label}
        </TextSpan>
      </label>
    </>
  )
}
