import { css } from '@emotion/react'

import { TextParagraph, TextSpan } from 'src/components/Text'
import {
  cardContainer,
  headingText,
  descriptionText,
} from 'src/features/trade/styles/DemoStyles'

interface TradeCardProps {
  title: string
  description: string | string[]
  children?: React.ReactNode
}

interface TradeCardTipsProps {
  tips: React.ReactNode[]
}

/**
 * A standardized card component used throughout the trade interface
 * Provides consistent styling for title, description, and content areas
 */
export function TradeCard({ title, description, children }: TradeCardProps) {
  const descriptionArray = Array.isArray(description)
    ? description
    : [description]
  return (
    <div css={cardContainer}>
      <TextParagraph css={headingText}>{title}</TextParagraph>
      {descriptionArray.map((d, index) => (
        <TextParagraph css={descriptionText} key={`${index}-description`}>
          {d}
        </TextParagraph>
      ))}
      {children}
    </div>
  )
}

const tipsContainer = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export function TradeCardTips({ tips }: TradeCardTipsProps) {
  return (
    <div css={tipsContainer}>
      {tips.map((tip, index) => (
        <TextSpan key={`${index}-tip`} css={tipsText}>
          {tip}
        </TextSpan>
      ))}
    </div>
  )
}

const tipsText = css`
  ${descriptionText}

  margin: 0;
  white-space: pre-line;

  ul,
  ol {
    margin-top: 8px;
  }

  li {
    margin-bottom: 4px;
    line-height: 1.4;
  }
`
